import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface DebuggerState {
   showDebugger: boolean;
   setShowDebugger: (shouldShow: boolean) => void;

   showTestClinicians: boolean;
   setShowTestClinicians: (shouldShow: boolean) => void;

   showTipsAndResources: boolean;
   setShowTipsAndResources: (shouldShow: boolean) => void;
}

const isDevelopment = process.env.NODE_ENV === 'development';

export const useDebuggerStore = create(
   persist<DebuggerState>(
      (set) => ({
         showDebugger: isDevelopment,
         setShowDebugger: (shouldShow: boolean) =>
            set({ showDebugger: shouldShow }),

         showTestClinicians: isDevelopment,
         setShowTestClinicians: (shouldShow) =>
            set({ showTestClinicians: shouldShow }),

         showTipsAndResources: isDevelopment,
         setShowTipsAndResources: (shouldShow) =>
            set({ showTipsAndResources: shouldShow }),
      }),
      {
         name: 'debugger-store',
      },
   ),
);
