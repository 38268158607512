import {
   Accordion,
   AccordionButton,
   AccordionItem,
   AccordionPanel,
   Box,
   Flex,
   Text,
} from '@chakra-ui/react';

import { NextLinkButton } from '@/components/NextLinkButton/NextLinkButton';

import { Icon } from '../Icon';
import { BottomRightCircles } from '../Images';
import { motion } from 'framer-motion';
import { useSearchParams } from 'next/navigation';

export const WeeklyTrackAccordion = () => {
   const searchParams = useSearchParams();

   const isWeeklyPauseFinished =
      searchParams.get('weekly-pause-finished') === '1';

   if (isWeeklyPauseFinished) {
      return null;
   }

   return (
      <Box
         as={motion.div}
         sx={{
            '--height': {
               base: '19.25rem',
               lg: '15.625rem',
            },
         }}
         initial={{
            opacity: 0,
            maxHeight: 0,
         }}
         animate={{
            opacity: 1,
            maxHeight: 'var(--height)',
         }}
         pos="relative"
         zIndex={4}
      >
         <Flex
            flexDir="column"
            position="absolute"
            top="-1.62rem"
            left={5}
            alignItems="center"
            justifyContent="center"
            zIndex={4}
         >
            <Icon
               name="vertical-dashed-line"
               h={12}
               w="1px"
               color="background.primary"
            />

            <Box
               boxSize=".7rem"
               bg="background.primary"
               rounded="full"
               opacity={1}
               flexShrink={0}
               flexGrow={0}
            />
         </Flex>

         <Accordion allowToggle defaultIndex={[0]}>
            <AccordionItem
               border="none"
               borderRadius="12px"
               boxShadow="0px 5px 5px rgba(0, 0, 0, 0.15)"
            >
               {({ isExpanded }) => (
                  <>
                     <h2>
                        <AccordionButton
                           px={{ base: 3, lg: 4 }}
                           minH="56px"
                           pl={{ base: 10, lg: '42px' }}
                           bg="background.secondary"
                           _hover={{
                              bg: 'background.secondary',
                           }}
                           borderRadius="12px"
                           borderBottomLeftRadius={isExpanded ? 0 : '12px'}
                           borderBottomRightRadius={isExpanded ? 0 : '12px'}
                           pos="relative"
                           borderBottom="1px solid"
                           borderColor="rgba(65, 119, 119, 0.1)"
                        >
                           <Text size="eyebrow" color="text.primary">
                              Weekly reflection
                           </Text>
                           <Icon
                              name="chevron-down-light"
                              w={5}
                              h={5}
                              color="text.primary"
                              ml="auto"
                              transition="transform .2s ease"
                              transform={
                                 isExpanded ? 'rotate(180deg)' : 'rotate(0deg)'
                              }
                              pos="absolute"
                              right={{ base: 3, lg: 4 }}
                           />
                        </AccordionButton>
                     </h2>
                     <AccordionPanel
                        pt={4}
                        pb={6}
                        px={6}
                        bg="background.secondary"
                        borderBottomLeftRadius="12px"
                        borderBottomRightRadius="12px"
                        color="text.primary"
                        pos="relative"
                     >
                        <Text mb={4}>
                           These tailored questionnaires provide valuable
                           insight into your treatment progress. Regularly
                           measuring your progress enables you and your care
                           team to work together to make personalized adjustment
                           to your care plan.
                        </Text>
                        <NextLinkButton size="sm" href="/weekly-pause">
                           Begin
                        </NextLinkButton>

                        <BottomRightCircles
                           pos="absolute"
                           right={0}
                           bottom={0}
                           display={{
                              base: 'none',
                              lg: 'block',
                           }}
                        />
                     </AccordionPanel>
                  </>
               )}
            </AccordionItem>
         </Accordion>
      </Box>
   );
};
