import { useTheme } from '@emotion/react';
import { type customTheme } from '@innerwell/chakra/theme';
import { Fragment } from 'react';

import {
   type ChartData,
   type ChartMargin,
   POPOVER_WIDTH,
   type PopoverInfo,
} from '@/components/Charts/DailyMoodChart';
import { getMoodColor } from '@/components/Charts/DailyMoodChart/Points';
import { getLevelAccentColorFromScore } from '@/components/Charts/LevelsChart';
import { type LevelsType } from '@/components/Charts/LevelsChart/levelsMap';

type MouseOverData = {
   score: number;
   date: number;
   x: number;
};

type Props = {
   data: ChartData[];
   popoverInfo: PopoverInfo | null;
   margin: ChartMargin;
   triggerLineWidth: number | string;
   chartWidth: number;
   chartHeight: number;
   type?: LevelsType;
   xScale: d3.ScaleTime<number, number> | d3.ScaleLinear<number, number, never>;
   yScale: d3.ScaleLinear<number, number, never>;
   xScaleType?: 'time' | 'linear';
   onMouseOver: (data: MouseOverData) => void;
   onMouseOut: () => void;
};

export const ChartPopoverTrigger: React.FC<Props> = ({
   data,
   popoverInfo,
   triggerLineWidth,
   margin,
   chartWidth,
   chartHeight,
   type,
   xScale,
   xScaleType = 'time',
   yScale,
   onMouseOver,
   onMouseOut,
}) => {
   const theme = useTheme() as typeof customTheme;

   return (
      <>
         {data.map((d, i) => {
            const score =
               'score' in d ? d.score : 'averageScore' in d && d.averageScore;
            const strokeColor =
               xScaleType === 'time'
                  ? score
                     ? getMoodColor(score)
                     : theme.colors.line.primary
                  : score && type
                    ? getLevelAccentColorFromScore(score, type)
                    : theme.colors.line.primary;

            const last = data.at(-1);
            const isHovered = popoverInfo?.date === d.date;
            const xScaleValue = xScaleType === 'time' ? d.date : i;

            let popoverX = Math.max(
               margin.left - 5,
               xScale(xScaleValue) - POPOVER_WIDTH / 2,
            );

            if (last && popoverX > chartWidth - POPOVER_WIDTH - margin.right) {
               popoverX =
                  xScale(xScaleType === 'time' ? last.date : i) -
                  POPOVER_WIDTH +
                  5;
            }

            return (
               <Fragment key={`${d.date}-${score}`}>
                  <defs>
                     <linearGradient
                        id={`${d.date}-${score}`}
                        x1="0"
                        y1="0"
                        x2="0"
                        y2="100%"
                        gradientUnits="userSpaceOnUse"
                     >
                        <stop
                           stopColor={strokeColor}
                           offset="0%"
                           stopOpacity={0.1}
                        />
                        <stop stopColor={strokeColor} offset="100%" />
                     </linearGradient>
                  </defs>
                  <g transform={`translate(${xScale(xScaleValue)}, 0)`}>
                     <line
                        y1={margin.top - 20}
                        y2={yScale(score || 1)}
                        strokeWidth={isHovered ? (score ? 1 : 2) : 0}
                        stroke={`url(#${d.date}-${score})`}
                     />

                     <line
                        y1={margin.top}
                        y2={chartHeight - margin.bottom}
                        stroke="transparent"
                        strokeWidth={triggerLineWidth}
                        onMouseOver={() =>
                           onMouseOver({
                              score: score || 0,
                              date: d.date,
                              x: popoverX,
                           })
                        }
                        onMouseOut={onMouseOut}
                     />
                  </g>
               </Fragment>
            );
         })}
      </>
   );
};
