'use client';

import {
   Box,
   Flex,
   Heading,
   Skeleton,
   Tab,
   TabList,
   TabPanel,
   TabPanels,
   Tabs,
} from '@chakra-ui/react';
import { parseAsInteger, useQueryState } from 'nuqs';

import { usePatientProgram } from '@/contexts/patient-program-context';
import { usePatientInKetamineProgram } from '@/services/patient/hooks/use-patient-in-ketamine-program';
import { motion } from 'framer-motion';
import { MoodsTab } from '@/components/JourneyTabs/MoodsTab';
import { GoalsTab } from '@/components/JourneyTabs/GoalsTab';
import { SessionsTab } from '@/components/JourneyTabs/SessionsTab';

export enum JourneyTabs {
   Moods,
   Goals,
   Sessions,
}

export const JourneyClientPage = () => {
   const {
      programPhase: { program },
   } = usePatientProgram();
   const isInKetamineProgram = usePatientInKetamineProgram();

   const [tabIndex, setTabIndex] = useQueryState(
      'tab',
      parseAsInteger.withDefault(JourneyTabs.Moods),
   );

   const handleTabChange = (newIndex: JourneyTabs) => {
      setTabIndex(newIndex);
   };

   return (
      <Flex
         mx="auto"
         maxW="container.md"
         pt={{ base: 10, lg: 20 }}
         zIndex={10}
         pos="relative"
         flexDir="column"
         color="white"
      >
         <Heading mb={4}>My Journey</Heading>

         <Tabs
            as={Skeleton}
            index={tabIndex}
            onChange={handleTabChange}
            isLazy
            variant="outline-button"
            pb={14}
            isLoaded={!!program}
            borderRadius="lg"
         >
            <Box maxW="450px">
               <TabList>
                  <Tab>Moods</Tab>
                  <Tab>Goals</Tab>
                  {isInKetamineProgram ? <Tab>Sessions</Tab> : null}
               </TabList>
            </Box>

            <TabPanels>
               <TabPanel>
                  <motion.div
                     initial={{ opacity: 0, y: 10 }}
                     animate={{ opacity: 1, y: 0 }}
                     transition={{ duration: 0.6, ease: 'easeInOut' }}
                  >
                     <MoodsTab />
                  </motion.div>
               </TabPanel>
               <TabPanel>
                  <motion.div
                     initial={{ opacity: 0, y: 10 }}
                     animate={{ opacity: 1, y: 0 }}
                     transition={{ duration: 0.6, ease: 'easeInOut' }}
                  >
                     <GoalsTab />
                  </motion.div>
               </TabPanel>
               {isInKetamineProgram ? (
                  <TabPanel>
                     <motion.div
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.6, ease: 'easeInOut' }}
                     >
                        <SessionsTab />
                     </motion.div>
                  </TabPanel>
               ) : null}
            </TabPanels>
         </Tabs>
      </Flex>
   );
};
