export const levelsMap = {
   anxiety: {
      maxScore: 21,
      barColorThreshold: {
         green: 9,
         orange: 14,
      },
   },
   depression: {
      maxScore: 27,
      barColorThreshold: {
         green: 9,
         orange: 14,
      },
   },
   ptsd: {
      maxScore: 80,
      barColorThreshold: {
         green: 33,
         orange: 45,
      },
   },
};

export type LevelsType = keyof typeof levelsMap;
