import {
   type ChartData,
   type ChartPeriod,
} from '@/components/Charts/DailyMoodChart';
import { createContext, useContext, useState } from 'react';

const ChartContext = createContext<{
   isDosesVisible: boolean;
   setIsDosesVisible: React.Dispatch<React.SetStateAction<boolean>>;
   data: ChartData[];
   setData: React.Dispatch<React.SetStateAction<ChartData[]>>;
   period: ChartPeriod;
   setPeriod: React.Dispatch<React.SetStateAction<ChartPeriod>>;
}>({
   isDosesVisible: false,
   setIsDosesVisible: () => {},
   data: [],
   setData: () => {},
   period: 'w',
   setPeriod: () => {},
});

export const ChartProvider = ({ children }: { children: React.ReactNode }) => {
   const [isDosesVisible, setIsDosesVisible] = useState(false);
   const [data, setData] = useState<ChartData[]>([]);
   const [period, setPeriod] = useState<ChartPeriod>('w');

   return (
      <ChartContext.Provider
         value={{
            isDosesVisible,
            setIsDosesVisible,
            data,
            setData,
            period,
            setPeriod,
         }}
      >
         {children}
      </ChartContext.Provider>
   );
};

export const useChart = () => {
   const context = useContext(ChartContext);

   if (context === undefined) {
      throw new Error('useChart must be used within a ChartProvider');
   }
   return context;
};
