import { Flex, Text } from '@chakra-ui/react';
import { DateTime } from 'luxon';

import { useChart } from '@/contexts/chart-context';

import { type ChartPeriod } from '.';

type Props = {
   period: ChartPeriod;
};

export const DateInfoAndTrackCTA = ({ period }: Props) => {
   const { data } = useChart();

   // const scores = data.filter((d) =>
   //    'score' in d ? d.score : 'averageScore' in d && d.averageScore
   // );

   // const average = useMemo(() => {
   //    const averageSum = scores.reduce(
   //       (acc, d) =>
   //          acc +
   //          ('score' in d
   //             ? d.score || 0
   //             : 'averageScore' in d
   //             ? d.averageScore || 0
   //             : 0),
   //       0
   //    );

   //    return averageSum > 0 ? averageSum / scores.length : 0;
   // }, [scores]);

   if (!data.length) {
      return null;
   }

   const firstEntry = data[0];
   const lastEntry = data[data.length - 1];

   const startDate = DateTime.fromMillis(firstEntry.date);
   const endDate =
      period === '6m'
         ? DateTime.fromMillis(lastEntry.date).plus({ days: 6 })
         : DateTime.fromMillis(lastEntry.date);

   return (
      <Flex
         alignItems="center"
         justifyContent={{ base: 'space-between', lg: 'center' }}
         flexDir="column"
         pos="relative"
      >
         <Text color="text.primary">
            {startDate.toFormat('LLL d')} - {endDate.toFormat('LLL d')}
         </Text>
         {/*
         <Box
            ml={3}
            border="1px solid"
            borderColor="line.primary"
            borderRadius="4px"
            px={2}
            py="5px"
         >
            <Text
               lineHeight={1}
               size="paragraphSmall"
               color="text.primary"
               fontWeight={500}
            >
               Average mood
               <Text ml={1} as="span" color="#499C9C" fontWeight={600}>
                  {average ? Math.round(average) : '-'}
               </Text>
            </Text>
         </Box>
            */}
      </Flex>
   );
};
