import * as d3 from 'd3';
import { Fragment } from 'react';

import { type ChartPeriod, periodMap } from '.';
import { type ChartData } from './Chart';
import { getMoodColor } from './Points';

type Props = {
   data: ChartData[];
   xScale: d3.ScaleTime<number, number>;
   period: ChartPeriod;
};
export const ChartLineGradient = ({ data, xScale, period }: Props) => {
   const dataPoints = data.filter(
      (d) =>
         ('score' in d && !!d.score) ||
         ('averageScore' in d && !!d.averageScore),
   );

   const lineWidth =
      xScale(dataPoints[dataPoints.length - 1].date) -
      xScale(dataPoints[0].date);

   const lineXScale = d3
      .scaleLinear()
      .domain([dataPoints[0].date, dataPoints[dataPoints.length - 1].date])
      .range([0, lineWidth]);

   return (
      <defs id="gradient">
         <linearGradient id="lineGradient" x1="0%" y1="0%" x2="100%" y2="0%">
            {dataPoints.map((dataPoint, i) => {
               const score =
                  'score' in dataPoint
                     ? dataPoint.score || 0
                     : 'averageScore' in dataPoint
                       ? dataPoint.averageScore || 0
                       : 0;

               const offsetPercentage =
                  (lineXScale(dataPoint.date) / lineWidth) * 100;
               return (
                  <Fragment key={i}>
                     {i > 0 && (
                        <stop
                           offset={`${
                              offsetPercentage -
                              periodMap[period].lineGradientOffsetPercentage
                           }%`}
                           stopColor={getMoodColor(score)}
                        />
                     )}
                     <stop
                        offset={`${offsetPercentage}%`}
                        stopColor={getMoodColor(score)}
                     />
                     {i + 1 < dataPoints.length && (
                        <stop
                           offset={`${
                              offsetPercentage +
                              periodMap[period].lineGradientOffsetPercentage
                           }%`}
                           stopColor={getMoodColor(score)}
                        />
                     )}
                  </Fragment>
               );
            })}
         </linearGradient>
      </defs>
   );
};
