import {
   type FormsortAnswersResponse,
   FormsortFormTypes,
   type ProgramGoalsAnswers,
} from '@innerwell/dtos';
import { useQuery, type UseQueryOptions } from '@tanstack/react-query';

import { webApiClient } from '@/api-client/apiClient';
import { useSession } from '@/contexts/session-context';

import { queryKeys } from '@/types/query-keys';

export const useMyProgramGoals = (
   options: Omit<
      UseQueryOptions<FormsortAnswersResponse[]>,
      'queryKey' | 'queryFn' | 'initialData'
   >,
) => {
   const { data: sessionData } = useSession();

   const { data, ...rest } = useQuery({
      queryKey: queryKeys.myProgramGoals,
      queryFn: async () => {
         const result = await webApiClient.formsort.list({
            query: {
               formsort_form_type: [FormsortFormTypes.ProgramGoals],
            },
         });
         return result.body;
      },
      enabled: !!sessionData,
      refetchOnMount: 'always',
      ...options,
   });

   return {
      ...rest,
      programGoals: data
         ? (data as FormsortAnswersResponse<ProgramGoalsAnswers>[])
         : null,
   };
};
