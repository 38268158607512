import { useQuery } from '@tanstack/react-query';

import { webApiClient } from '@/api-client/apiClient';

import { queryKeys } from '@/types/query-keys';

export const useTodayMoodTracking = () => {
   return useQuery({
      queryKey: queryKeys.simpleMoodTracking,
      queryFn: async () => {
         const response = await webApiClient.moodTracking.getToday();

         return response.body;
      },
   });
};
