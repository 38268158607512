import {
   MoodChartIcon0,
   MoodChartIcon1,
   MoodChartIcon2,
   MoodChartIcon3,
   MoodChartIcon4,
   MoodChartIcon5,
   MoodChartIcon6,
} from '@/components/Charts/chart-icons';
import { type ChartMargin } from '@/components/Charts/DailyMoodChart';

import { getMoodColor } from './Points';

const MoodChartIcons = {
   0: MoodChartIcon0,
   1: MoodChartIcon1,
   2: MoodChartIcon2,
   3: MoodChartIcon3,
   4: MoodChartIcon4,
   5: MoodChartIcon5,
   6: MoodChartIcon6,
};

const ICON_HEIGHT = 24;

type Props = {
   yScale: d3.ScaleLinear<number, number, never>;
   margin: ChartMargin;
   width: number;
};

export const ChartYAxis: React.FC<Props> = ({ yScale, margin, width }) => {
   return (
      <>
         {yScale.ticks(6).map((score, i) => {
            if (i >= 0 && i <= 6) {
               const IconComponent =
                  MoodChartIcons[i as keyof typeof MoodChartIcons];

               return (
                  <g transform={`translate(0, ${yScale(score)})`} key={score}>
                     <line
                        x1={margin.left}
                        x2={width - margin.right}
                        stroke={getMoodColor(score)}
                        strokeDasharray="4px"
                        strokeWidth={1}
                        opacity={0.3}
                     />
                     <IconComponent
                        transform={`translate(0, ${-ICON_HEIGHT / 2})`}
                     />
                  </g>
               );
            }
         })}
      </>
   );
};
