import { MoodTrackingRangeEnum } from '@innerwell/dtos';
import { getUtcDateFromTz, getWeekStart } from '@innerwell/utils';
import { useQuery } from '@tanstack/react-query';
import { DateTime } from 'luxon';

import { webApiClient } from '@/api-client/apiClient';
import { useSession } from '@/contexts/session-context';

import { queryKeys } from '@/types/query-keys';

export const useDailyMoodTracking = () => {
   const { data: sessionData } = useSession();
   const fetchWeeklyMoodTracking = async () => {
      const weekStart = getUtcDateFromTz(getWeekStart(DateTime.now()));
      const weekEnd = weekStart.plus({ days: 7 }).startOf('day');
      const response = await webApiClient.moodTracking.progress({
         query: {
            dateStart: weekStart.toISODate(),
            dateEnd: weekEnd.toISODate(),
            period: MoodTrackingRangeEnum.Day,
         },
      });

      return response.body;
   };

   const { data: weeklyMoodProgressData, ...rest } = useQuery({
      queryKey: queryKeys.weeklyMoodTracking,
      queryFn: fetchWeeklyMoodTracking,
      enabled: !!sessionData,
      refetchInterval: 1000 * 60, // 1 minute
   });

   return {
      data: weeklyMoodProgressData ?? null,
      ...rest,
   };
};
