import { initContract } from '@ts-rest/core';
import {
   allowedFollowOnsResponseDtoSchema,
   canBuyFollowOnsResponseDtoSchema,
} from '@innerwell/dtos';

const c = initContract();
export const followons = c.router(
   {
      followOnsAllowed: {
         method: 'GET',
         path: '/followons/allowed',
         responses: {
            200: allowedFollowOnsResponseDtoSchema,
         },
      },
      canBuyFollowOns: {
         method: 'GET',
         path: '/followons/can-buy',
         responses: {
            200: canBuyFollowOnsResponseDtoSchema,
         },
      },
   },
   {
      strictStatusCodes: true,
   },
);
