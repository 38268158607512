import { Flex, type FlexProps, Switch, Text } from '@chakra-ui/react';

import { useChart } from '@/contexts/chart-context';

export const MedicineSessionsToggle: React.FC<FlexProps> = (flexProps) => {
   const { setIsDosesVisible } = useChart();

   const handleToggle = () => {
      setIsDosesVisible((prev) => !prev);
   };

   return (
      <Flex
         alignItems="center"
         pos={{ lg: 'absolute' }}
         top={{ lg: 7 }}
         right={{ lg: 6 }}
         mt={{ base: 5, lg: 0 }}
         justifyContent={{ base: 'center', lg: 'flex-end' }}
         {...flexProps}
      >
         <Text color="text.primary" fontSize="xs" fontWeight={500} as="span">
            Medicine sessions
         </Text>

         <Switch
            colorScheme="buttonSecondary"
            onChange={handleToggle}
            ml={2.5}
            size="lg"
         />
      </Flex>
   );
};
