import {
   Accordion,
   AccordionButton,
   AccordionItem,
   type AccordionProps,
   Box,
   Flex,
   Heading,
   Text,
} from '@chakra-ui/react';
import { type MoodTrackingItem } from '@innerwell/dtos';
import { shortenText } from '@innerwell/utils';
import { DateTime } from 'luxon';

import { formatDateNicely } from '@/utils/formatting';
import { WEEKLY_MOOD_OPTIONS } from '@/utils/weekly-mood-options';

import {
   CutoffOrangeCircleTopLeft,
   ReflectionsCardCirclesBg,
   SandCirclesChartBottomRight,
} from '../BackgroundGraphics';
import { Icon } from '../Icon';

interface IProps extends AccordionProps {
   mood: MoodTrackingItem;
}

const CUT_TEXT_AFTER_CHARS = 70;

const JourneyMoodCard: React.FC<IProps> = ({ mood, ...props }) => {
   const { score, details, date } = mood;

   const moodInfo = WEEKLY_MOOD_OPTIONS.find((opt) => opt.value === score);

   if (!moodInfo) {
      return null;
   }

   const { mainColor, text: moodName, iconName } = moodInfo;

   const dateTime = DateTime.fromISO(date, {
      zone: 'utc',
   });
   const formattedDate = formatDateNicely(dateTime);

   const day = dateTime.toFormat('cccc');
   const dayNumber = dateTime.toFormat('dd');
   const monthYear = dateTime.toFormat('MMM yyyy');

   return (
      <Accordion
         bg="white"
         borderRadius={12}
         maxW="none"
         allowToggle
         overflow="hidden"
         {...props}
      >
         <AccordionItem border="none" color="white">
            {({ isExpanded }) => {
               const textLonger = (details || '').length > CUT_TEXT_AFTER_CHARS;

               const textFormatted = !details
                  ? ''
                  : !isExpanded
                    ? shortenText(details, CUT_TEXT_AFTER_CHARS, '...')
                    : details;

               return (
                  <AccordionButton
                     p={0}
                     alignItems="stretch"
                     cursor={textLonger ? 'pointer' : 'initial'}
                     flexDir={{ base: 'column', md: 'row' }}
                     textAlign="left"
                     pos="relative"
                  >
                     <Box display={{ base: 'block', md: 'none' }}>
                        <Text
                           size="paragraphSmall"
                           color="text.primary"
                           px={5}
                           pt={4}
                        >
                           {formattedDate}
                        </Text>

                        <ReflectionsCardCirclesBg
                           pos="absolute"
                           left={0}
                           top={0}
                           pointerEvents="none"
                        />
                     </Box>

                     <Flex
                        flexDir="column"
                        bg="linear-gradient(216.83deg, rgba(255, 156, 75, 0.12) 30.52%, rgba(255, 156, 75, 0) 91.09%);"
                        p={4}
                        pr={2}
                        textAlign="left"
                        w="110px"
                        flexShrink={0}
                        pos="relative"
                        overflow="hidden"
                        display={{ base: 'none', md: 'flex' }}
                     >
                        <CutoffOrangeCircleTopLeft
                           pos="absolute"
                           top={0}
                           left={0}
                        />
                        <SandCirclesChartBottomRight
                           width="60px"
                           height="60px"
                           pos="absolute"
                           right={-4}
                           bottom={-3}
                        />
                        <Text color="text.primary">{day}</Text>
                        <Heading
                           size="small"
                           color="background.primary"
                           lineHeight={1}
                        >
                           {dayNumber}
                        </Heading>
                        <Text color="text.primary">{monthYear}</Text>
                     </Flex>
                     <Flex
                        flexDir="column"
                        flex={1}
                        pt={{ base: 3, md: 4 }}
                        pb={4}
                        pl={{ base: 5, md: 6 }}
                     >
                        <Flex>
                           <Icon
                              name={iconName}
                              w={{ base: 5, md: 8 }}
                              h={{ base: 5, md: 8 }}
                              color={mainColor}
                           />

                           <Flex
                              pl={3}
                              gap={2}
                              direction="column"
                              alignItems="flex-start"
                           >
                              <Flex
                                 px={1.5}
                                 py="3px"
                                 bg={mainColor}
                                 borderRadius={4}
                                 justifyContent="center"
                                 alignItems="center"
                              >
                                 <Text
                                    lineHeight={1}
                                    textAlign="center"
                                    size="paragraphSmallest"
                                    fontWeight={600}
                                    opacity={0.75}
                                 >
                                    {moodName}
                                 </Text>
                              </Flex>

                              <Text
                                 ml={{ base: -8, md: 0 }}
                                 pr={{ base: 5, md: 20 }}
                                 color="text.primary"
                                 fontWeight={500}
                                 textAlign="left"
                                 size={
                                    textFormatted
                                       ? 'paragraphLarge'
                                       : 'paragraph'
                                 }
                                 fontStyle={textFormatted ? 'normal' : 'italic'}
                                 opacity={textFormatted ? 1 : 0.8}
                              >
                                 {textFormatted || 'No notes added.'}
                              </Text>
                           </Flex>

                           {textLonger ? (
                              <Icon
                                 name="chevron-down-light"
                                 w={8}
                                 h={8}
                                 color="text.primary"
                                 ml="auto"
                                 transform={
                                    isExpanded
                                       ? 'rotate(180deg)'
                                       : 'rotate(0deg)'
                                 }
                                 transition="transform 0.2s"
                                 pos={{ base: 'absolute', md: 'relative' }}
                                 top={{ base: 4, md: 6 }}
                                 right={5}
                              />
                           ) : null}
                        </Flex>
                     </Flex>
                  </AccordionButton>
               );
            }}
         </AccordionItem>
      </Accordion>
   );
};

export default JourneyMoodCard;
