import { MoodTrackingRangeEnum } from '@innerwell/dtos';
import { getUtcDateFromTz } from '@innerwell/utils';
import { useQuery } from '@tanstack/react-query';
import { DateTime } from 'luxon';

import { webApiClient } from '@/api-client/apiClient';

import { queryKeys } from '@/types/query-keys';

export const useDosingHistory = () => {
   return useQuery({
      queryKey: queryKeys.dosingHistory,
      queryFn: async () => {
         const response = await webApiClient.moodTracking.getHistory({
            query: {
               period: MoodTrackingRangeEnum.Day,
               dateEnd: getUtcDateFromTz(DateTime.now()).toISO(),
               dateStart: getUtcDateFromTz(
                  DateTime.now().minus({ year: 1 }),
               ).toISO(),
            },
         });

         return response.body;
      },
      refetchOnMount: true,
      refetchInterval: 10000,
   });
};
