import { MoodTrackingRangeEnum } from '@innerwell/dtos';
import { DateTime } from 'luxon';

import { type ChartMargin } from '.';

export type PopoverInfo = {
   x: number;
   score: number;
   date: number;
};

interface IProps {
   w: number;
   bg: string;
   textColor: string;
   popoverInfo: PopoverInfo;
   subTitle: string;
   periodSegment?: MoodTrackingRangeEnum;
   chartMargin: ChartMargin;
}

export const ChartPopover: React.FC<IProps> = ({
   chartMargin,
   w,
   bg,
   periodSegment = MoodTrackingRangeEnum.Day,
   textColor,
   popoverInfo,
   subTitle,
}) => {
   const textPadding = {
      top: 17,
      left: 10,
   };

   const { x, score, date } = popoverInfo;
   const y = chartMargin.top - 75;

   const startDate = DateTime.fromMillis(date);

   return (
      <g>
         <rect
            id="popover-white-bg"
            x={x}
            y={y}
            width={w}
            height="66px"
            rx="8"
            fill="white"
            opacity={1}
         />
         <rect
            id="popover-overlay-mood-color-bg"
            x={x}
            y={y}
            width={w}
            height="66px"
            rx="8"
            fill={bg}
            opacity={0.1}
         />
         <g fill={textColor}>
            <text
               fontSize="12"
               x={x + textPadding.left}
               y={y + textPadding.top}
            >
               {subTitle}
            </text>
            <text
               fontSize="24"
               x={x + textPadding.left}
               y={y + textPadding.top + 23}
               fill={bg}
            >
               {score}
            </text>
            <text
               fontSize="10"
               x={x + textPadding.left}
               y={y + textPadding.top + 38}
            >
               {startDate.toLocaleString(DateTime.DATE_MED)}
               {periodSegment === MoodTrackingRangeEnum.Week &&
                  `- ${startDate
                     .plus({ days: 6 })
                     .toLocaleString(DateTime.DATE_MED)}`}
            </text>
         </g>
      </g>
   );
};
