import {
   Accordion,
   AccordionButton,
   AccordionItem,
   type AccordionProps,
   Box,
   Flex,
   Heading,
   Text,
} from '@chakra-ui/react';
import { shortenText } from '@innerwell/utils';

import {
   CutoffOrangeCircleCenterLeft,
   ProgramGoalsCardCirclesBg,
   SandCirclesChartBottomRight,
} from '../BackgroundGraphics';
import { Icon } from '../Icon';

interface IProps extends AccordionProps {
   question: string;
   questionNumber: number;
   answer: string | string[];
   showBorderBottom?: boolean;
}

const CUT_TEXT_AFTER_CHARS = 133;

const ProgramGoalsCard: React.FC<IProps> = ({
   question,
   questionNumber,
   answer,
   showBorderBottom = false,
   ...props
}) => {
   return (
      <Accordion
         bg="white"
         maxW="none"
         allowToggle
         pos="relative"
         overflow="hidden"
         borderBottom={showBorderBottom ? '1px solid' : 'none'}
         borderColor="rgba(255, 156, 75, 0.2);"
         {...props}
      >
         <AccordionItem border="none" color="white">
            {({ isExpanded }) => {
               let textLonger = false;
               let textFormatted = '';
               if (typeof answer === 'string') {
                  textLonger = (answer || '').length > CUT_TEXT_AFTER_CHARS;

                  textFormatted = !answer
                     ? ''
                     : !isExpanded
                       ? shortenText(answer, CUT_TEXT_AFTER_CHARS, '...')
                       : answer;
               }

               return (
                  <AccordionButton
                     p={0}
                     flexDirection="column"
                     alignItems="flex-start"
                     cursor={textLonger ? 'pointer' : 'initial'}
                     _hover={{
                        backgroundColor: 'white',
                     }}
                  >
                     {textLonger && (
                        <Icon
                           name="chevron-down-light"
                           w={8}
                           h={8}
                           color="text.primary"
                           ml="auto"
                           transition="transform 0.2s"
                           transform={
                              isExpanded ? 'rotate(180deg)' : 'rotate(0deg)'
                           }
                           pos="absolute"
                           top={{ base: 4, md: 6 }}
                           right={5}
                        />
                     )}

                     <Box display={{ base: 'block', md: 'none' }}>
                        <Text
                           size="paragraphSmall"
                           color="text.primary"
                           px={5}
                           pt={4}
                        >
                           Question {questionNumber}
                        </Text>

                        <ProgramGoalsCardCirclesBg
                           pos="absolute"
                           left={0}
                           top={0}
                           pointerEvents="none"
                        />
                     </Box>

                     <Flex pr={14} textAlign="left" color="text.primary">
                        <Flex
                           flexDir="column"
                           bg="linear-gradient(216.83deg, rgba(255, 156, 75, 0.12) 30.52%, rgba(255, 156, 75, 0) 91.09%);"
                           p={4}
                           py={6}
                           textAlign="left"
                           w="100px"
                           flexShrink={0}
                           pos="relative"
                           overflow="hidden"
                           display={{ base: 'none', md: 'flex' }}
                        >
                           <CutoffOrangeCircleCenterLeft
                              pos="absolute"
                              top="27px"
                              left={0}
                           />
                           <SandCirclesChartBottomRight
                              width="60px"
                              height="60px"
                              pos="absolute"
                              right={-1}
                              bottom={-7}
                           />
                           <Text fontWeight={400} size="paragraphSmall">
                              Question
                           </Text>
                           <Heading
                              lineHeight={1}
                              size="small"
                              color="background.primary"
                           >
                              {questionNumber}
                           </Heading>
                        </Flex>
                        <Flex
                           flexDir="column"
                           alignItems="start"
                           px={{ base: 5, lg: 7 }}
                           pt={{ base: 3, lg: 6 }}
                           pb={{ base: 5, lg: 6 }}
                        >
                           <Text
                              size="paragraphLarge"
                              fontWeight={600}
                              mb={{ base: 2, lg: 3 }}
                           >
                              {question}
                           </Text>
                           {typeof answer === 'string' ? (
                              <Text fontWeight={500}>“{textFormatted}”</Text>
                           ) : (
                              <Flex gap="8px" flexWrap="wrap">
                                 {answer.map((answerText) => {
                                    return (
                                       <Box
                                          bg="accent.peach"
                                          key={answerText}
                                          px={2}
                                          py="3px"
                                          borderRadius="4px"
                                          __css={{
                                             '&:first-letter': {
                                                textTransform: 'uppercase',
                                             },
                                          }}
                                       >
                                          <Text
                                             fontWeight={400}
                                             size="paragraphSmall"
                                          >
                                             {answerText.replaceAll('_', ' ')}
                                          </Text>
                                       </Box>
                                    );
                                 })}
                              </Flex>
                           )}
                        </Flex>
                     </Flex>
                  </AccordionButton>
               );
            }}
         </AccordionItem>
      </Accordion>
   );
};

export default ProgramGoalsCard;
