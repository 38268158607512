import { DateTime } from 'luxon';

export const periodMap = {
   w: {
      amountOfDays: 7,
      xLabelsRange: 1,
      xTicksMap: 7,
      triggerLineWidth: '25%',
      pointCircleRadius: 4.5,
      averageInfoText: 'Weekly average',
      xLabelFormat: (date: Date) => DateTime.fromJSDate(date).toFormat('ccccc'),
      dateTextFormat: (firstDate: number, lastDate: number) =>
         `${DateTime.fromMillis(firstDate).toFormat(
            'MMM d',
         )} - ${DateTime.fromMillis(lastDate).toFormat('MMM d')}`,
      lineGradientOffsetPercentage: 3,
   },
   m: {
      amountOfDays: 31,
      xLabelsRange: 7,
      xTicksMap: 31,
      triggerLineWidth: '4.5%',
      pointCircleRadius: 6,
      averageInfoText: 'Monthly average',
      xLabelFormat: (date: Date) => DateTime.fromJSDate(date).toFormat('dd'),
      dateTextFormat: (firstDate: number, lastDate: number) =>
         `${DateTime.fromMillis(firstDate).toFormat(
            'MMM d',
         )} - ${DateTime.fromMillis(lastDate).toFormat('MMM d')}`,
      lineGradientOffsetPercentage: 1.5,
   },
   '6m': {
      amountOfDays: 180,
      xLabelsRange: 31,
      xTicksMap: 31,
      triggerLineWidth: '4.8%',
      pointCircleRadius: 6,
      averageInfoText: '6 months average',
      xLabelFormat: (date: Date) => DateTime.fromJSDate(date).toFormat('MMM'),
      dateTextFormat: (firstDate: number, lastDate: number) =>
         `${DateTime.fromMillis(firstDate).toFormat(
            'MMM d',
         )} - ${DateTime.fromMillis(lastDate)
            .plus({ days: 6 })
            .toFormat('MMM d')}`,
      lineGradientOffsetPercentage: 1,
   },
   y: {
      amountOfDays: 365,
      xLabelsRange: 31,
      xTicksMap: 365,
      triggerLineWidth: 25,
      pointCircleRadius: 6,
      averageInfoText: 'Yearly average',
      xLabelFormat: (date: Date) => DateTime.fromJSDate(date).toFormat('MMM'),
      dateTextFormat: (firstDate: number, lastDate: number) =>
         `${DateTime.fromMillis(firstDate).toFormat(
            'MMM d',
         )} - ${DateTime.fromMillis(lastDate).toFormat('MMM d')}`,
      lineGradientOffsetPercentage: 1,
   },
};
