import { useQuery } from '@tanstack/react-query';

import { webApiClient } from '@/api-client/apiClient';

import { queryKeys } from '@/types/query-keys';

const REFETCH_INTERVAL = 5000;

export const useWeeklyTrackProgress = () => {
   const { data: anxietyData, ...anxietyRest } = useQuery({
      queryKey: queryKeys.weeklyMoodProgressAnxiety(),
      queryFn: async () => {
         const response = await webApiClient.moodTracking.getAnxietyLevels({
            query: {
               size: 200,
               sort: 'asc',
            },
         });
         return response.body;
      },
      refetchOnMount: true,
      refetchInterval: REFETCH_INTERVAL,
   });

   const { data: depressionData, ...depressionRest } = useQuery({
      queryKey: queryKeys.weeklyMoodProgressDepression(),
      queryFn: async () => {
         const response = await webApiClient.moodTracking.getDepressionLevels({
            query: {
               size: 200,
               sort: 'asc',
            },
         });

         return response.body;
      },
      refetchOnMount: true,
      refetchInterval: REFETCH_INTERVAL,
   });

   const { data: ptsdData, ...ptsdRest } = useQuery({
      queryKey: queryKeys.weeklyMoodProgressPtsd(),
      queryFn: async () => {
         const response = await webApiClient.moodTracking.getPcl5Levels({
            query: {
               size: 200,
               sort: 'asc',
            },
         });

         return response.body;
      },
      refetchOnMount: true,
      refetchInterval: REFETCH_INTERVAL,
   });

   return {
      anxiety: {
         data: anxietyData ?? null,
         ...anxietyRest,
      },
      depression: {
         data: depressionData ?? null,
         ...depressionRest,
      },
      ptsd: {
         data: ptsdData ?? null,
         ...ptsdRest,
      },
   };
};
