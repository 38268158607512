import {
   Box,
   Flex,
   type FlexProps,
   Modal,
   ModalContent,
   ModalOverlay,
   type ModalProps,
   Text,
   type TextProps,
   type UseDisclosureProps,
} from '@chakra-ui/react';
import { type ReactNode } from 'react';

import CustomAvatar, { type AvatarSize } from '../Avatar/Avatar';
import { Illustration } from '../Illustration';

export type BoxedSandModalProps = {
   onCloseComplete?: () => void;
   children?: ReactNode;
   hideIllustration?: boolean;
} & UseDisclosureProps &
   ModalProps;

type SubcomponentProps = {
   Heading: typeof Heading;
   Avatar: typeof Avatar;
   ButtonGroup: typeof ButtonGroup;
};

const BoxedSandModal: React.FC<BoxedSandModalProps> & SubcomponentProps = ({
   isOpen = false,
   onClose,
   onCloseComplete,
   children,
   hideIllustration = false,
   ...modalProps
}) => {
   const handleCloseComplete = () => {
      if (onCloseComplete) {
         onCloseComplete();
      }
   };

   const handleClose = () => {
      if (onClose) {
         onClose();
      }
   };

   return (
      <Modal
         isOpen={isOpen}
         onClose={handleClose}
         isCentered
         closeOnEsc={false}
         closeOnOverlayClick={false}
         onCloseComplete={handleCloseComplete}
         size="inside"
         // eslint-disable-next-line jsx-a11y/no-autofocus
         autoFocus={false}
         {...modalProps}
      >
         <ModalOverlay />
         <ModalContent
            borderRadius={12}
            px={{ base: 5, lg: 14 }}
            textAlign="center"
            overflow={{ base: 'auto', lg: 'hidden' }}
            background="linear-gradient(216.83deg, rgba(255, 156, 75, 0.12) 30.52%, rgba(255, 156, 75, 0) 91.09%), #FFFFFF"
         >
            {!hideIllustration && (
               <Illustration
                  src="/illustrations/three-circles-top-right.svg"
                  pos="absolute"
                  right={0}
                  top={0}
               />
            )}

            {children}
         </ModalContent>
      </Modal>
   );
};

const Heading = ({ children, ...props }: { children: string } & TextProps) => (
   <Text size="leadTitle" fontWeight={500} mb={1} {...props}>
      {children}
   </Text>
);

const Avatar = ({
   avatarUrl = '/images/ben-medrano-peach.jpg',
   size = 'sm',
   desktopSize = 'sm',
   name,
   title,
   hasVerifiedBadge,
   ...flexProps
}: {
   avatarUrl?: string;
   size?: AvatarSize;
   desktopSize?: AvatarSize;
   name?: string;
   title?: string;
   hasVerifiedBadge?: boolean;
} & FlexProps) => (
   <Flex alignItems="center" gap={4} {...flexProps}>
      <CustomAvatar
         size={size}
         desktopSize={desktopSize}
         url={avatarUrl}
         hasVerifiedBadge={hasVerifiedBadge}
      />

      {name || title ? (
         <Box textAlign="left" color="text.secondary">
            {name ? <Text fontWeight={500}>{name}</Text> : null}
            {title ? <Text>{title}</Text> : null}
         </Box>
      ) : null}
   </Flex>
);

const ButtonGroup = ({
   children,
   ...flexProps
}: { children: ReactNode } & FlexProps) => (
   <Flex
      flexDir="column"
      minW="248px"
      gap={4}
      mt={5}
      mb={{ base: 4, lg: 6 }}
      {...flexProps}
   >
      {children}
   </Flex>
);

BoxedSandModal.Heading = Heading;
BoxedSandModal.Avatar = Avatar;
BoxedSandModal.ButtonGroup = ButtonGroup;

export default BoxedSandModal;
