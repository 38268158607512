import {
   Box,
   Flex,
   Slider,
   SliderThumb,
   SliderTrack,
   useTheme,
} from '@chakra-ui/react';
import { type customTheme } from '@innerwell/chakra/theme';
import { type LevelResultDto } from '@innerwell/dtos';
import * as d3 from 'd3';
import { AnimatePresence, motion } from 'framer-motion';
import { DateTime } from 'luxon';
import React, { useEffect, useRef, useState } from 'react';
import useMeasure from 'react-use-measure';

import {
   ChartPopover,
   ChartPopoverTrigger,
   type PopoverInfo,
} from '@/components/Charts/DailyMoodChart';
import { Icon } from '@/components/Icon';

import { levelsMap, type LevelsType } from './levelsMap';

export interface DepressionChartData {
   score: number;
   date: number;
}

export const POPOVER_WIDTH = 100;
const VERTICAL_BAR_WIDTH = 9;
const VERTICAL_BAR_ROUNDED_RADIUS = VERTICAL_BAR_WIDTH / 2;

export const getLevelAccentColorFromScore = (
   score: number,
   type: LevelsType,
) => {
   if (score < levelsMap[type].barColorThreshold.green) {
      return '#177777';
   }
   if (score < levelsMap[type].barColorThreshold.orange) {
      return '#FF9C4B';
   }

   return '#B74B4B';
};

interface Props {
   height: string;
   type: LevelsType;
   levels: LevelResultDto[];
}

export const LevelsChart: React.FC<Props> = ({
   height: wrapperHeight,
   levels,
   type,
}) => {
   const [ref, { height, width }] = useMeasure();

   const [chartXScroll, setChartXScroll] = useState(0);

   const handleSliderChange = (value: number) => {
      setChartXScroll(value);
   };

   const chartCalculatedWidth = Math.max((levels.length || 1) * 50, width);
   const chartNeedsScroll = chartCalculatedWidth > width;

   return (
      <>
         <Box h={wrapperHeight}>
            <Box ref={ref} h="100%" w="100%" position="relative">
               {height && width && levels ? (
                  <Chart
                     data={levels
                        .filter((d) => typeof d.score === 'number' && d.date)
                        .map((d) => ({
                           score: d.score || 0,
                           date: DateTime.fromISO(d.date).toMillis(),
                        }))}
                     width={chartCalculatedWidth}
                     height={height}
                     chartXScroll={chartXScroll}
                     type={type}
                  />
               ) : null}
            </Box>
         </Box>

         {chartNeedsScroll ? (
            <Box px={4} mt={7} mb={3}>
               <Slider
                  aria-label="slider-for-anxiety"
                  defaultValue={0}
                  value={chartXScroll}
                  onChange={handleSliderChange}
               >
                  <SliderTrack bg="line.primary" />
                  <SliderThumb boxSize={6}>
                     <Box>
                        <Icon
                           name="slider-scroll-handle"
                           w={12}
                           h={12}
                           color="accent.orange"
                        />
                     </Box>
                  </SliderThumb>
               </Slider>
            </Box>
         ) : null}
      </>
   );
};

const Chart: React.FC<{
   data: DepressionChartData[];
   width: number;
   height: number;
   chartXScroll: number;
   type: LevelsType;
}> = ({ data, width, height, chartXScroll, type }) => {
   const theme = useTheme() as typeof customTheme;
   const themeLinePrimary = theme.colors.text.secondary;
   const svgRef = useRef<SVGSVGElement>(null);
   const scrollableSvgRef = useRef<HTMLDivElement>(null);

   const labelsWidth = 40;

   const margin = {
      top: 80,
      right: 20,
      bottom: 30,
      left: 20,
   };

   const xScale = d3
      .scaleLinear()
      .domain([0, data.length])
      .range([margin.left, width - margin.right]);

   const yTicks = levelsMap[type].maxScore;

   const yScale = d3
      .scaleLinear()
      .domain([0, yTicks])
      .range([height - margin.bottom, margin.top]);

   // Scroll the div on slider update
   useEffect(() => {
      const scrollableSvg = scrollableSvgRef.current;
      if (scrollableSvg) {
         const totalScrollableWidth =
            scrollableSvg.scrollWidth - scrollableSvg.offsetWidth;

         scrollableSvg.scrollLeft = totalScrollableWidth * (chartXScroll / 100);
      }
   }, [chartXScroll]);

   // Popover
   const [popoverInfo, setPopoverInfo] = useState<PopoverInfo | null>(null);

   return (
      <Flex w="full" pos="relative">
         <Box w={`${labelsWidth}px`}>
            <svg viewBox={`0 0 ${labelsWidth} ${height}`}>
               <g>
                  {/* Y Axis */}
                  {yScale.ticks(5).map((score: number) => {
                     return (
                        <g
                           transform={`translate(0, ${yScale(score)})`}
                           key={score}
                        >
                           <text
                              fill={theme.colors.text.primary}
                              alignmentBaseline="middle"
                           >
                              {score}
                           </text>
                        </g>
                     );
                  })}
               </g>
            </svg>
         </Box>

         <Box
            overflow="hidden"
            height={`${height + 20}px`}
            flex={1}
            maxW={width - labelsWidth}
            ref={scrollableSvgRef}
         >
            <svg
               height={height}
               viewBox={`0 0 ${width} ${height}`}
               ref={svgRef}
            >
               <g>
                  {/* Y Axis */}
                  {yScale.ticks(5).map((score: number) => {
                     return (
                        <g
                           transform={`translate(0, ${yScale(score)})`}
                           key={score}
                        >
                           <line
                              x1={margin.left}
                              x2={width - margin.right}
                              stroke={themeLinePrimary}
                              strokeDasharray="4px"
                              strokeWidth={1}
                              opacity={0.5}
                           />
                        </g>
                     );
                  })}

                  {/* Vertical Bars */}
                  {data.map((d, i) => {
                     return (
                        <React.Fragment key={d.date}>
                           <g
                              transform={`translate(${
                                 xScale(i) - VERTICAL_BAR_ROUNDED_RADIUS
                              }, ${
                                 yScale(d.score || 1) +
                                 VERTICAL_BAR_ROUNDED_RADIUS
                              })`}
                           >
                              {/* The rounded tip */}
                              <circle
                                 fill={getLevelAccentColorFromScore(
                                    d.score,
                                    type,
                                 )}
                                 r={VERTICAL_BAR_ROUNDED_RADIUS}
                                 cx={VERTICAL_BAR_ROUNDED_RADIUS}
                              />
                              {/* Vertical bar */}
                              <rect
                                 height={
                                    height -
                                    yScale(d.score || 1) -
                                    margin.bottom -
                                    VERTICAL_BAR_ROUNDED_RADIUS
                                 }
                                 width={VERTICAL_BAR_WIDTH}
                                 fill={getLevelAccentColorFromScore(
                                    d.score,
                                    type,
                                 )}
                              />
                           </g>
                           <text
                              textAnchor="middle"
                              fill="#121723"
                              fontSize="12"
                              y={height - 2}
                              x={xScale(i)}
                           >
                              {DateTime.fromMillis(d.date, {
                                 zone: 'utc',
                              }).toFormat('MMM dd')}
                           </text>
                        </React.Fragment>
                     );
                  })}

                  {/* White box to cut the circle if the score is < 2 */}
                  <rect
                     fill="white"
                     width={width}
                     height="10px"
                     x={0}
                     y={height - margin.bottom}
                  />

                  {/* Popover trigger Y line */}
                  <ChartPopoverTrigger
                     data={data}
                     type={type}
                     xScale={xScale}
                     yScale={yScale}
                     xScaleType="linear"
                     popoverInfo={popoverInfo}
                     chartHeight={height}
                     chartWidth={width}
                     margin={margin}
                     onMouseOver={(data) => setPopoverInfo(data)}
                     onMouseOut={() => setPopoverInfo(null)}
                     triggerLineWidth={80}
                  />

                  {/* Popover */}
                  <AnimatePresence>
                     {popoverInfo ? (
                        <motion.g
                           initial={{ opacity: 0 }}
                           animate={{ opacity: 1 }}
                           exit={{ opacity: 0 }}
                           transition={{
                              duration: 0.1,
                           }}
                        >
                           <ChartPopover
                              textColor={theme.colors.text.primary}
                              bg={getLevelAccentColorFromScore(
                                 popoverInfo.score,
                                 type,
                              )}
                              popoverInfo={popoverInfo}
                              w={POPOVER_WIDTH}
                              subTitle="Score"
                              chartMargin={margin}
                           />
                        </motion.g>
                     ) : null}
                  </AnimatePresence>
               </g>
            </svg>
         </Box>
      </Flex>
   );
};
