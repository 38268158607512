/**
 * A hook to determine if current user has roles to see/use features
 * */

import {
   type FailedPaymentDtoWithoutInstallments,
   FailedPaymentsRetryStatus,
   MissedAppointmentFeePaid,
   type MissedAppointmentsResponseDto,
   type SharedProgramPhases,
} from '@innerwell/dtos';
import { useMemo } from 'react';

import { useMissedAppointments } from '@/hooks/react-query/useMissedAppointments';

import { usePatientProgram } from '@/contexts/patient-program-context';
import { LOCKED_PHASES, type LockedExplanation } from '@/utils/lockedPhases';

import { LockedStatusType } from './types';
import { useFailedPayments } from '../../../hooks/react-query/useFailedPayments';

export type LockedStatus =
   | {
        type: LockedStatusType.PHASE;
        explanation: LockedExplanation;
     }
   | {
        type: LockedStatusType.PAYMENT_FAILED;
        paymentFailedCdt: FailedPaymentDtoWithoutInstallments;
     }
   | {
        type: LockedStatusType.MISSED_APPOINTMENT;
        missedAppointment: MissedAppointmentsResponseDto;
     };

type UserAccess = {
   isLocked: boolean;
   statuses: LockedStatus[];
};

export const useUserAccess = (): UserAccess => {
   const {
      programPhase: { phase },
   } = usePatientProgram();

   const { data: failedPayments, isLoading: isFailedPaymentsLoading } =
      useFailedPayments();
   const { data: missedAppointments, isLoading: isMissedAppointmentsLoading } =
      useMissedAppointments();

   const paymentFailedRecord = failedPayments?.find(
      (p) => p.retryStatus !== FailedPaymentsRetryStatus.SUCCESS,
   );

   const missedAppointmentRecord = missedAppointments?.find(
      (appt) =>
         ![
            MissedAppointmentFeePaid.Success,
            MissedAppointmentFeePaid.Canceled,
         ].includes(appt.status),
   );

   const lockedStatuses: UserAccess = useMemo(() => {
      if (!phase || isFailedPaymentsLoading || isMissedAppointmentsLoading) {
         return {
            isLocked: false,
            statuses: [],
         };
      }

      const phaseTyped = phase as SharedProgramPhases;
      const explanation = LOCKED_PHASES.get(phaseTyped);

      const statuses: LockedStatus[] = [];

      if (paymentFailedRecord) {
         statuses.push({
            paymentFailedCdt: paymentFailedRecord,
            type: LockedStatusType.PAYMENT_FAILED,
         });
      }

      if (missedAppointmentRecord) {
         statuses.push({
            type: LockedStatusType.MISSED_APPOINTMENT,
            missedAppointment: missedAppointmentRecord,
         });
      }

      if (LOCKED_PHASES.has(phaseTyped) && explanation) {
         statuses.push({
            explanation,
            type: LockedStatusType.PHASE,
         });
      }

      return {
         isLocked: statuses.length > 0,
         statuses,
      };
   }, [
      isFailedPaymentsLoading,
      isMissedAppointmentsLoading,
      missedAppointmentRecord,
      paymentFailedRecord,
      phase,
   ]);

   return lockedStatuses;
};
