'use client';

import { AxiosError } from 'axios';
import { type PropsWithChildren } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { useSession } from '@/contexts/session-context';

const FallbackRender = () => {
   return <div>Loading...</div>;
};

const LoggedInUser = ({ children }: PropsWithChildren) => {
   const { status } = useSession({ required: true });

   if (status === 'authenticated') {
      return children;
   }

   return null;
};

export const WithLoggedInUser = ({ children }: PropsWithChildren) => {
   return (
      <ErrorBoundary
         onError={(e) => {
            if (e instanceof AxiosError && e.status === 401) {
               return;
            }
            throw e;
         }}
         fallbackRender={FallbackRender}
      >
         <LoggedInUser>{children}</LoggedInUser>
      </ErrorBoundary>
   );
};
