import { Box, Flex, Skeleton, Text } from '@chakra-ui/react';
import { useRouter, useSearchParams } from 'next/navigation';

import { useMyProgramGoals } from '@/hooks/react-query/useMyProgramGoals';

import { NextLinkButton } from '@/components/NextLinkButton/NextLinkButton';

import ProgramGoalsCard from '../Cards/ProgramGoalsCard';
import { JourneyWelcomeScreen } from '../Journey/JourneyWelcomeScreen';
import { useQueryClient } from '@tanstack/react-query';
import { queryKeys } from '@/types/query-keys';
import { type FormsortAnswersResponse } from '@innerwell/dtos';
import { useEffect, useRef, useState } from 'react';

export const GoalsTab = () => {
   const searchParams = useSearchParams();
   const { push } = useRouter();

   const queryClient = useQueryClient();

   const programGoalsFinished = searchParams.get('program_goals_finished');

   const existingProgramGoals = queryClient.getQueryData<
      FormsortAnswersResponse[]
   >(queryKeys.myProgramGoals);

   const [isProgramGoalsSynced, setIsProgramGoalsSynced] = useState(
      () =>
         !programGoalsFinished ||
         !!(programGoalsFinished && !existingProgramGoals?.length),
   );

   const { programGoals, status, isRefetching } = useMyProgramGoals({
      refetchInterval: programGoalsFinished ? 5000 : 0,
   });

   useEffect(() => {
      if (existingProgramGoals?.length && isProgramGoalsSynced) {
         push('/journey?tab=1');
      }
   }, [existingProgramGoals, isProgramGoalsSynced, push]);

   const firstProgramGoals = useRef(programGoals);

   useEffect(() => {
      // When we add new program goals, we want to show loader until the new data is fetched
      if (!firstProgramGoals.current && programGoals) {
         setIsProgramGoalsSynced(true);

         return;
      }

      // When we edit the program goals, we want to show loader until the new data is fetched
      if (
         firstProgramGoals.current &&
         programGoals &&
         firstProgramGoals.current?.at(0)?.updatedAt !==
            programGoals.at(0)?.updatedAt
      ) {
         setIsProgramGoalsSynced(true);
      }
   }, [programGoals]);

   const answers = programGoals?.at(0)?.answers ?? false;
   const goalsTracked = programGoalsFinished || answers ? true : false;

   const handleBtnClick = () => {
      push('/set-program-goals');
   };

   return status === 'pending' ? (
      <Skeleton w="full" h="300px" borderRadius="12px" />
   ) : (
      <>
         {goalsTracked ? (
            <>
               <Flex
                  my={{ base: 3, lg: 6 }}
                  alignItems={{ base: 'flex-start', lg: 'center' }}
                  flexDir={{ base: 'column', lg: 'row' }}
               >
                  <Text fontWeight={500} mb={{ base: 6, lg: 0 }}>
                     Thanks for reflecting on your “why” for treatment and for
                     being specific about your hopes and goals for healing. Here
                     is a summary of what you shared. You can use this as a
                     guidepost throughout your journey and for ongoing
                     conversations with your care team.
                  </Text>

                  <NextLinkButton
                     href="/set-program-goals"
                     flexShrink={0}
                     w={{ base: 'auto', lg: 'auto' }}
                     minW="200px"
                     size="sm"
                     ml={{ lg: 14 }}
                  >
                     {goalsTracked ? 'Edit program goals' : 'Set program goals'}
                  </NextLinkButton>
               </Flex>

               <Skeleton
                  isLoaded={!!answers && isProgramGoalsSynced}
                  h={isRefetching ? '80px' : 'auto'}
                  borderRadius="12px"
                  pb={16}
                  mt={8}
                  minH="80px"
               >
                  {answers ? (
                     <Box
                        boxShadow="0px 5px 5px rgba(0, 0, 0, 0.15);"
                        borderRadius={12}
                        overflow="hidden"
                     >
                        <ProgramGoalsCard
                           questionNumber={1}
                           question="I’d like to work on these areas of my life:"
                           answer={
                              answers.areas_of_focus.includes('other')
                                 ? [
                                      ...answers.areas_of_focus.filter(
                                         (a: unknown) => a !== 'other',
                                      ),
                                      `Other: ${answers.areas_of_focus_other}`,
                                   ]
                                 : answers.areas_of_focus
                           }
                           showBorderBottom
                        />
                        <ProgramGoalsCard
                           questionNumber={2}
                           question="I’d like to strengthen these aspects of myself:"
                           answer={
                              answers.areas_to_strengthen.includes('other')
                                 ? [
                                      ...answers.areas_to_strengthen.filter(
                                         (a: unknown) => a !== 'other',
                                      ),
                                      `Other: ${answers.areas_to_strengthen_other}`,
                                   ]
                                 : answers.areas_to_strengthen
                           }
                           showBorderBottom
                        />
                        <ProgramGoalsCard
                           questionNumber={3}
                           question="I’d like to better understand and manage these feelings or emotions:"
                           answer={
                              answers.feelings_to_understand.includes('other')
                                 ? [
                                      ...answers.feelings_to_understand.filter(
                                         (a: unknown) => a !== 'other',
                                      ),
                                      `Other: ${answers.feelings_to_understand_other}`,
                                   ]
                                 : answers.feelings_to_understand
                           }
                           showBorderBottom
                        />
                        <ProgramGoalsCard
                           questionNumber={4}
                           question="I’d like to change and better understand these behavior patterns:"
                           answer={answers.behavior_patterns}
                           showBorderBottom
                        />
                        <ProgramGoalsCard
                           questionNumber={5}
                           question="I felt healthy, happy and balanced..."
                           answer={answers.reflect_happy_times}
                           showBorderBottom
                        />
                        <ProgramGoalsCard
                           questionNumber={6}
                           question="Six months after completing this program, I’d like my life to look like..."
                           answer={answers.visualize_your_life_six_months}
                           showBorderBottom
                        />
                        <ProgramGoalsCard
                           questionNumber={7}
                           question="Here’s what needs to happen for these changes to occur..."
                           answer={answers.visualize_life_six_months_how}
                           showBorderBottom
                        />
                     </Box>
                  ) : null}
               </Skeleton>
            </>
         ) : (
            <JourneyWelcomeScreen
               text={`You’ve just taken a big steps toward healing
            by meeting with your Psychiatric Clinician. Now it’s time to
            reflect on your “why” for the treatment. Being clear about your
            hopes and aims can be a helpful foundation for the journey ahead.
            `}
               image="/images/welcome-screen-goals.jpg"
               btnLabel="Set program goals"
               onBtnClick={handleBtnClick}
            />
         )}
      </>
   );
};
