import { Box, Button, Text, useDisclosure } from '@chakra-ui/react';
import { IntakeProgramPhases } from '@innerwell/dtos';
import { isIntakeProgramAfterOrEqual } from '@innerwell/utils';
import { useMemo, useState } from 'react';

import { useContactConsent } from '@/hooks/react-query/useContactConsent';
import { useUpdateContactConsent } from '@/hooks/react-query/useUpdateContactConsent';

import { usePatientProgram } from '@/contexts/patient-program-context';

import CustomCheckbox from '../Devkit/CustomFormControl/CustomCheckbox/CustomCheckbox';
import { Icon } from '../Icon';
import BoxedSandModal from '../Modals/BoxedSandModal';

export const HomeConfirmContactConsent = () => {
   const {
      programPhase: { phase },
   } = usePatientProgram();

   const isMedicalIntakeFinished = useMemo(() => {
      return isIntakeProgramAfterOrEqual(
         phase,
         IntakeProgramPhases.MedicalIntakeFinished,
      );
   }, [phase]);

   const {
      data: isContactConsentAlreadyAcceptedData,
      isLoading: isContactConsentLoading,
   } = useContactConsent({
      enabled: isMedicalIntakeFinished,
   });

   const isContactConsentAlreadyAccepted =
      isContactConsentAlreadyAcceptedData?.jsonBody['cdtf-confirmed'] ?? false;

   const isContactConsentVisible =
      !isContactConsentAlreadyAccepted &&
      isMedicalIntakeFinished &&
      !isContactConsentLoading;

   const contactConsentModalProps = useDisclosure();

   const [isContactConsentAccepted, setIsContactConsentAccepted] =
      useState(false);

   const { mutate: updateContactConsent, isPending: isUpdatingContactConsent } =
      useUpdateContactConsent();

   const handleConfirmContactConsentClick = () => {
      updateContactConsent();
   };

   if (!isContactConsentVisible) {
      return null;
   }

   return (
      <BoxedSandModal {...contactConsentModalProps} isOpen>
         <BoxedSandModal.Heading mb={2}>
            Consent to Receive Texts from Innerwell
         </BoxedSandModal.Heading>

         <Text opacity={0.7} fontWeight={500} color="text.secondary" mb={5}>
            Please review and accept the terms below
         </Text>

         <Box
            textAlign="left"
            bg="background.secondary"
            fontSize="xs"
            p={3}
            border="1px solid rgba(0,0,0,.1)"
            borderRadius={12}
            mb={4}
         >
            {`By checking this box, I consent to receive automated text (SMS)
            messages regarding information about my treatment, as well as
            information and updates about Innerwell's products and services.
            Message and data rates may apply. Reply HELP for help or STOP to
            opt-out at any time. Mobile information will only be shared to
            provide the requested service. Message frequency may vary.`}
         </Box>
         <CustomCheckbox
            w="full"
            textAlign="left"
            defaultChecked={isContactConsentAccepted}
            variant="dark"
            value={String(isContactConsentAccepted)}
            onChange={() => {
               setIsContactConsentAccepted((prev) => !prev);
            }}
            checked={String(isContactConsentAccepted) === 'true'}
            mb={4}
         >
            <Text fontSize="xs" lineHeight={1.2}>
               I consent to receive texts from Innerwell and its affiliated
               partners.
            </Text>
         </CustomCheckbox>

         <BoxedSandModal.ButtonGroup>
            <Button
               size="sm"
               isDisabled={!isContactConsentAccepted}
               rightIcon={
                  <Icon name="chevron-right" w={3.5} h={3.5} strokeWidth={2} />
               }
               onClick={handleConfirmContactConsentClick}
               isLoading={isUpdatingContactConsent}
            >
               Confirm and continue
            </Button>
         </BoxedSandModal.ButtonGroup>
      </BoxedSandModal>
   );
};
