import {
   type AddonMagentoCategoriesResponseDto,
   type AddonPurchasesWithoutCount,
} from '@innerwell/dtos';
import { initContract } from '@ts-rest/core';

const c = initContract();

export const appointmentBank = c.router(
   {
      getAvailableAddonAppointments: {
         method: 'GET',
         path: '/appointment-bank/available-addons',
         responses: {
            200: c.type<AddonPurchasesWithoutCount[]>(),
         },
         summary: 'Returns available addon appointments from appointment bank.',
         description:
            'Appointments that have used count >= total count are not returned.',
      },
      getMagentoAddonCategoryIds: {
         method: 'GET',
         path: '/addons/addon-magento-categories',
         responses: {
            200: c.type<AddonMagentoCategoriesResponseDto>(),
         },
         summary:
            'Returns therapy and prescriber category id from Magento which should be used to fetch addons.',
      },
   },
   {
      strictStatusCodes: true,
   },
);
