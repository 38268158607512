import { useQuery } from '@tanstack/react-query';

import { webApiClient } from '@/api-client/apiClient';

import { queryKeys } from '@/types/query-keys';
import useMyPatient from './useMyPatient';

export const useAddonEncounters = () => {
   const { data: patient } = useMyPatient();

   const { data, ...rest } = useQuery({
      queryKey: queryKeys.addonEncounters(),
      queryFn: async () => {
         const response =
            await webApiClient.scheduling.getAvailableAddonEncounters({});
         return response.body;
      },
      enabled: !!patient,
      refetchInterval: 30000,
      refetchOnWindowFocus: true,
   });

   return {
      ...rest,
      therapyCategoryId: data?.therapyCategoryId ?? null,
      prescriberCategoryId: data?.prescriberCategoryId ?? null,
      therapyAddons: data?.therapyAddons ?? [],
      prescriberAddons: data?.prescriberAddons ?? [],
   };
};
