import { Box, useTheme } from '@chakra-ui/react';
import { type customTheme } from '@innerwell/chakra/theme';
import {
   type MoodTrackingProgressResultDto,
   type MoodTrackingRangeEnum,
   type MoodTrackingResultDto,
} from '@innerwell/dtos';
import * as d3 from 'd3';
import { AnimatePresence, motion } from 'framer-motion';
import { DateTime } from 'luxon';

import {
   ChartDoses,
   ChartFullLine,
   ChartPoints,
   ChartPopover,
   ChartPopoverTrigger,
   ChartXAxis,
   ChartYAxis,
   periodMap,
   POPOVER_WIDTH,
   type PopoverInfo,
} from '@/components/Charts/DailyMoodChart';
import { getMoodColor } from '@/components/Charts/DailyMoodChart/Points';

import { useChart } from '@/contexts/chart-context';
import { WEEKLY_MOOD_OPTIONS } from '@/utils/weekly-mood-options';
import { useRef, useState } from 'react';

export type ChartSimpleData = Omit<MoodTrackingResultDto, 'date'> & {
   date: number;
};
export type ChartAverageData = Omit<MoodTrackingProgressResultDto, 'date'> & {
   date: number;
};

export type ChartData = ChartSimpleData | ChartAverageData;

export type ChartMargin = {
   top: number;
   right: number;
   bottom: number;
   left: number;
};

export const Chart: React.FC<{
   width: number;
   height: number;
   period: 'w' | 'm' | '6m' | 'y';
   periodSegment: MoodTrackingRangeEnum;
}> = ({ width, height, period, periodSegment }) => {
   const theme = useTheme() as typeof customTheme;
   const themeLinePrimary = theme.colors.line.primary;
   const svgRef = useRef<SVGSVGElement>(null);
   const { isDosesVisible, data } = useChart();

   const margin: ChartMargin = {
      top: 95,
      right: 20,
      bottom: 30,
      left: 50,
   };

   const xScale = d3
      .scaleTime()
      .domain(d3.extent(data.map((d) => d.date)) as [number, number])
      .range([margin.left, width - margin.right, periodMap[period].xTicksMap]);

   const xLabels =
      period === '6m'
         ? d3.timeMonth.range(
              DateTime.fromMillis(data[0].date).toJSDate(),
              DateTime.fromMillis(data[data.length - 1].date)
                 .plus({ seconds: 1 })
                 .toJSDate(),
              1,
           )
         : d3.timeDay.range(
              DateTime.fromMillis(data[0].date).toJSDate(),
              DateTime.fromMillis(data[data.length - 1].date)
                 .plus({ seconds: 1 })
                 .toJSDate(),
              periodMap[period].xLabelsRange,
           );

   const yScale = d3
      .scaleLinear()
      .domain([1, 7])
      .range([height - margin.bottom, margin.top]);

   let line: d3.Line<any>;

   // check if averageScore key is defined in data array
   const dataHasAverage = !!data.find((d) => 'averageScore' in d);

   if (dataHasAverage) {
      line = d3
         .line<ChartAverageData>()
         .x((d) => xScale(d.date))
         .y((d) => yScale(d.averageScore || 0))
         .curve(d3.curveMonotoneX)
         .defined(function (d) {
            return typeof d.averageScore === 'number' && d.averageScore > 0;
         });
   } else {
      line = d3
         .line<ChartSimpleData>()

         .x((d) => xScale(d.date))
         .y((d) => yScale(d.score || 0))
         .curve(d3.curveMonotoneX)
         .defined(function (d) {
            return typeof d.score === 'number';
         });
   }

   const [popoverInfo, setPopoverInfo] = useState<PopoverInfo | null>(null);

   return (
      <Box w="full" pos="relative">
         <svg viewBox={`0 0 ${width} ${height}`} ref={svgRef}>
            <g>
               {/* Y Axis */}
               <ChartYAxis yScale={yScale} margin={margin} width={width} />

               {/* X Axis */}
               <ChartXAxis
                  period={period}
                  height={height}
                  margin={margin}
                  xLabels={xLabels}
                  xScale={xScale}
                  strokeColor={themeLinePrimary}
               />

               {/* Doses */}
               {isDosesVisible ? (
                  <ChartDoses
                     height={height}
                     margin={margin}
                     xScale={xScale}
                     data={data}
                  />
               ) : null}

               {/* 
                  Line - deprecated for now, this is the line only connecting 
                  concurrect tracked days    
               */}
               {/*lineAttrs ? (
                  <ChartLine
                     dPath={lineAttrs}
                     data={data}
                     transitionDuration={2 * Math.max(data.length / 15, 1)}
                     xScale={xScale}
                     period={period}
                  />
               ) : null*/}

               {line ? (
                  <ChartFullLine
                     line={line}
                     xScale={xScale}
                     transitionDuration={2 * Math.max(data.length / 15, 1)}
                  />
               ) : null}

               {/* Points */}
               <ChartPoints
                  data={data}
                  circleRadius={periodMap[period].pointCircleRadius}
                  xScale={xScale}
                  yScale={yScale}
               />

               {/* Popover trigger Y line */}
               <ChartPopoverTrigger
                  data={data}
                  xScale={xScale}
                  yScale={yScale}
                  popoverInfo={popoverInfo}
                  chartHeight={height}
                  chartWidth={width}
                  margin={margin}
                  onMouseOver={(data) => setPopoverInfo(data)}
                  onMouseOut={() => setPopoverInfo(null)}
                  triggerLineWidth={periodMap[period].triggerLineWidth}
               />

               {/* Popover */}
               <AnimatePresence>
                  {popoverInfo ? (
                     <motion.g
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{
                           duration: 0.1,
                        }}
                     >
                        <ChartPopover
                           textColor={theme.colors.text.primary}
                           bg={getMoodColor(popoverInfo.score)}
                           popoverInfo={popoverInfo}
                           w={POPOVER_WIDTH}
                           periodSegment={periodSegment}
                           subTitle={
                              WEEKLY_MOOD_OPTIONS.find(
                                 (opt) => opt.value === popoverInfo.score,
                              )?.text || 'No data'
                           }
                           chartMargin={margin}
                        />
                     </motion.g>
                  ) : null}
               </AnimatePresence>
            </g>
         </svg>
      </Box>
   );
};
