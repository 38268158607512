import {
   Box,
   Flex,
   IconButton,
   type IconButtonProps,
   Text,
} from '@chakra-ui/react';

import { Illustration } from '../Illustration';
import { type FlexProps } from '@chakra-ui/layout';
import { Icon, type IconName } from '../Icon';
import { type ReactNode } from 'react';
import { Tooltip } from '../Tooltip/Tooltip';

export const HorizontalCard = ({
   title,
   children,
   footerContent,
   ...rest
}: {
   title: string;
   footerContent?: ReactNode | null;
} & FlexProps) => {
   return (
      <Flex
         flexDir="column"
         bg="white"
         borderRadius="12px"
         position="relative"
         border="2px solid #F2F1F6"
         overflow="hidden"
         {...rest}
      >
         <Flex
            flexDir="column"
            gap={1}
            py={{ base: 4, lg: 6 }}
            px={{ base: 5, lg: 6 }}
         >
            <Text size="leadText" color="card.secondary" fontWeight={600}>
               {title}
            </Text>
            <Flex alignItems="center" color="text.primary" gap={1}>
               {children}
            </Flex>
         </Flex>
         {footerContent ? (
            <Box
               bg="#F2F1F6"
               color="text.primary"
               px={{ base: 5, lg: 6 }}
               py={1}
               position="relative"
               zIndex={2}
               hideFrom="lg"
            >
               {footerContent}
            </Box>
         ) : null}
         <Illustration
            src="/illustrations/three-circles-bottom-right.svg"
            position="absolute"
            right={0}
            bottom={0}
         />
      </Flex>
   );
};

const CardButton = ({
   iconName,
   tooltipText,
   ...rest
}: { iconName: IconName; tooltipText?: string } & IconButtonProps) => {
   return (
      <Tooltip label={tooltipText} isDisabled={!tooltipText}>
         <IconButton
            position="absolute"
            right={{ base: 4, md: 3, xl: 6 }}
            top={{ base: 4, lg: 6 }}
            bg="accent.orange"
            w={{ base: 12, lg: 12 }}
            minW={12}
            h={12}
            minH={12}
            icon={<Icon name={iconName} color="text.primary" boxSize={4} />}
            border="6px solid #F7F2ED"
            zIndex={1}
            boxShadow="0px 6px 6px 0px rgba(0, 0, 0, 0.12)"
            _hover={{
               bg: 'accent.orange',
               boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.12);',
               _disabled: {
                  boxShadow: '0px 6px 6px 0px rgba(0, 0, 0, 0.12);',
               },
            }}
            _active={{
               boxShadow: '0px 1px 1px 0px rgba(0, 0, 0, 0.12);',
               _disabled: {
                  boxShadow: '0px 6px 6px 0px rgba(0, 0, 0, 0.12);',
               },
            }}
            _disabled={{
               opacity: 1,
               cursor: 'not-allowed',
            }}
            {...rest}
         />
      </Tooltip>
   );
};

HorizontalCard.Button = CardButton;
