import {
   Accordion,
   AccordionButton,
   AccordionItem,
   AccordionPanel,
   type AccordionProps,
   Box,
   Flex,
   Heading,
   Text,
   useDisclosure,
} from '@chakra-ui/react';
import { type HistoryItem, type MoodTrackingEnum } from '@innerwell/dtos';
import { useQueryClient } from '@tanstack/react-query';
import { DateTime } from 'luxon';
import { useState } from 'react';

import { formatDateNicely, formatDateNicelyShort } from '@/utils/formatting';
import { WEEKLY_MOOD_OPTIONS } from '@/utils/weekly-mood-options';

import { MoodCard } from './MoodCard';
import {
   CutoffOrangeCircleTopLeft,
   ReflectionsCardCirclesBg,
   SandCirclesChartBottomRight,
} from '../BackgroundGraphics';
import { Icon } from '../Icon';
import DailyMoodTrackingModal from '../Modals/DailyMoodTrackingModal';

import { queryKeys } from '@/types/query-keys';

type Props = { session: HistoryItem } & AccordionProps;

export const DosingInfoCard = ({ session, ...accordionProps }: Props) => {
   const queryClient = useQueryClient();
   const { date, yourIntention, dosage, moodDayAfter, moodDayBefore, feeling } =
      session;
   const dateTime = DateTime.fromISO(date, { zone: 'utc' });
   const day = dateTime.toFormat('cccc');
   const dayNumber = dateTime.toFormat('dd');
   const monthYear = dateTime.toFormat('MMM yyyy');

   const { date: moodBeforeDateISO, mood: moodBeforeMood } = moodDayBefore ?? {
      date: null,
      mood: null,
   };

   const moodBeforeDate = moodBeforeDateISO
      ? DateTime.fromISO(moodBeforeDateISO)
      : null;

   const { date: moodAfterDateISO, mood: moodAfterMood } = moodDayAfter ?? {
      date: null,
      mood: null,
   };

   const moodAfterDate = moodAfterDateISO
      ? DateTime.fromISO(moodAfterDateISO)
      : null;

   const moodInfoBefore = WEEKLY_MOOD_OPTIONS.find(
      (opt) => opt.value === session.moodDayBefore?.mood,
   );
   const moodInfoAfter = WEEKLY_MOOD_OPTIONS.find(
      (opt) => opt.value === session.moodDayAfter?.mood,
   );

   const initialMoodTrackingModal = useDisclosure();

   const handleStartDailyMood = () => {
      initialMoodTrackingModal.onOpen();
   };

   const [moodAfterTrackScore, setMoodAfterTrackScore] =
      useState<MoodTrackingEnum | null>(null);

   const handleFinishedDailyMoodTracking = (score: MoodTrackingEnum) => {
      setMoodAfterTrackScore(score);

      queryClient.refetchQueries({ queryKey: queryKeys.dosingHistory });
      queryClient.refetchQueries({ queryKey: queryKeys.weeklyMoodTracking });
   };
   const moodInfoAfterTrack = WEEKLY_MOOD_OPTIONS.find(
      (opt) => opt.value === moodAfterTrackScore,
   );

   return (
      <>
         <DailyMoodTrackingModal
            isOpen={initialMoodTrackingModal.isOpen}
            onClose={initialMoodTrackingModal.onClose}
            w="full"
            onFinishedDailyMoodTracking={handleFinishedDailyMoodTracking}
            date={DateTime.now().toISODate()}
         />
         <Accordion
            bg="white"
            borderRadius={12}
            maxW="none"
            allowToggle
            overflow="hidden"
            boxShadow="0px 5px 5px rgba(0, 0, 0, 0.15)"
            {...accordionProps}
         >
            <AccordionItem pos="relative" border="none" color="white">
               {({ isExpanded }) => {
                  return (
                     <>
                        <AccordionButton
                           p={0}
                           alignItems="stretch"
                           flexDir={{ base: 'column', md: 'row' }}
                           textAlign="left"
                           minH="105px"
                           _hover={{
                              bg: 'white',
                           }}
                        >
                           <Box display={{ base: 'block', md: 'none' }}>
                              <Text
                                 size="paragraphSmall"
                                 color="text.primary"
                                 px={5}
                                 pt={4}
                              >
                                 {formatDateNicely(dateTime)}
                              </Text>

                              <ReflectionsCardCirclesBg
                                 pos="absolute"
                                 left={0}
                                 top={0}
                                 pointerEvents="none"
                              />
                           </Box>

                           <Flex
                              flexDir="column"
                              bg="linear-gradient(216.83deg, rgba(255, 156, 75, 0.12) 30.52%, rgba(255, 156, 75, 0) 91.09%);"
                              pt={3}
                              pl={4}
                              pr={2}
                              justifyContent="flex-start"
                              position="absolute"
                              textAlign="left"
                              w="110px"
                              h="full"
                              flexShrink={0}
                              overflow="hidden"
                              display={{ base: 'none', md: 'flex' }}
                           >
                              <CutoffOrangeCircleTopLeft
                                 pos="absolute"
                                 top={0}
                                 left={0}
                              />
                              <SandCirclesChartBottomRight
                                 width="60px"
                                 height="60px"
                                 pos="absolute"
                                 right={-4}
                                 bottom={-3}
                              />
                              <Text color="text.primary">{day}</Text>
                              <Heading
                                 size="small"
                                 color="background.primary"
                                 lineHeight={1}
                              >
                                 {dayNumber}
                              </Heading>
                              <Text color="text.primary">{monthYear}</Text>
                           </Flex>
                           <Flex
                              flexDir="column"
                              flex={1}
                              pt={{ base: 1, md: 4 }}
                              pb={4}
                              pl={{ base: 5, md: '130px' }}
                              justifyContent="center"
                           >
                              <Flex>
                                 <Flex gap={2} alignItems="center">
                                    <Flex
                                       p={2}
                                       borderRadius="full"
                                       bgColor="accent.green"
                                    >
                                       <Icon
                                          name="pill"
                                          w={5}
                                          h={5}
                                          color="accent.green"
                                       />
                                    </Flex>

                                    <Text
                                       color="text.primary"
                                       fontWeight={600}
                                       size="paragraphLarge"
                                    >
                                       {dosage ?? 'Unknown '}mg
                                    </Text>
                                 </Flex>

                                 <Icon
                                    name="chevron-down-light"
                                    w={8}
                                    h={8}
                                    color="text.primary"
                                    ml="auto"
                                    transition="transform 0.2s ease"
                                    transform={
                                       isExpanded
                                          ? 'rotate(180deg)'
                                          : 'rotate(0deg)'
                                    }
                                    pos={{ base: 'absolute', md: 'relative' }}
                                    right={5}
                                 />
                              </Flex>
                           </Flex>
                        </AccordionButton>
                        <AccordionPanel p={0} pb={8} color="text.primary">
                           <Flex
                              flexDir="column"
                              alignItems="flex-start"
                              pl={{ base: 5, md: '130px' }}
                              pr={5}
                           >
                              <Text
                                 size="paragraphLarge"
                                 fontWeight={600}
                                 color="text.primary"
                                 mb={3}
                              >
                                 Your mood
                              </Text>
                              <Flex
                                 flexDir={{ base: 'column', lg: 'row' }}
                                 gap={{ base: 3, lg: 5 }}
                                 mb={6}
                                 w={{ base: 'full', lg: 'auto' }}
                              >
                                 {moodDayBefore &&
                                 moodBeforeMood &&
                                 moodBeforeDate ? (
                                    <MoodCard
                                       title="The day before"
                                       mood={moodBeforeMood}
                                       subTitle={formatDateNicelyShort(
                                          moodBeforeDate,
                                          { includeYear: false },
                                       )}
                                       iconName={moodInfoBefore?.iconName}
                                       iconText={moodInfoBefore?.text}
                                       bg={moodInfoBefore?.mainColor}
                                    />
                                 ) : (
                                    <MoodCard
                                       title="The day before"
                                       subTitle="No mood recorded"
                                       iconName="mood-very-good"
                                       hasStroke
                                       applyBlurToIcon
                                    />
                                 )}
                                 {moodDayAfter &&
                                 moodAfterMood &&
                                 moodAfterDate ? (
                                    <MoodCard
                                       title="The day after"
                                       subTitle={formatDateNicelyShort(
                                          moodAfterDate,
                                          { includeYear: false },
                                       )}
                                       mood={moodAfterMood}
                                       iconName={moodInfoAfter?.iconName}
                                       iconText={moodInfoAfter?.text}
                                       bg={moodInfoAfter?.mainColor}
                                    />
                                 ) : dateTime.plus({ days: 1 }).toISODate() ===
                                   DateTime.now().toISODate() ? (
                                    <MoodCard
                                       title="The day after"
                                       subTitle={
                                          moodAfterTrackScore
                                             ? formatDateNicelyShort(
                                                  DateTime.now(),
                                                  { includeYear: false },
                                               )
                                             : 'Record your mood now'
                                       }
                                       iconName={
                                          moodInfoAfterTrack
                                             ? moodInfoAfterTrack.iconName
                                             : 'mood-add'
                                       }
                                       onIconClick={
                                          moodAfterTrackScore
                                             ? undefined
                                             : handleStartDailyMood
                                       }
                                       bg={
                                          moodInfoAfterTrack
                                             ? moodInfoAfterTrack.mainColor
                                             : 'white'
                                       }
                                       iconText={
                                          moodInfoAfterTrack
                                             ? moodInfoAfterTrack.text
                                             : undefined
                                       }
                                       hasStroke={!moodAfterTrackScore}
                                    />
                                 ) : (
                                    <MoodCard
                                       title="The day after"
                                       subTitle="No mood recorded"
                                       iconName={
                                          WEEKLY_MOOD_OPTIONS[1].iconName
                                       }
                                       iconColor={
                                          WEEKLY_MOOD_OPTIONS[1].mainColor
                                       }
                                       hasStroke
                                       applyBlurToIcon
                                    />
                                 )}
                              </Flex>

                              {feeling || yourIntention ? (
                                 <>
                                    <Text
                                       size="paragraphLarge"
                                       fontWeight={600}
                                       color="text.primary"
                                       mb={2}
                                    >
                                       Your intention
                                    </Text>
                                    {feeling ? (
                                       <Box
                                          bg="rgba(255, 217, 187, 0.75)"
                                          borderRadius="4px"
                                          px={2.5}
                                          fontSize="xs"
                                          py={0.5}
                                          mb={2}
                                       >
                                          <Text>{feeling}</Text>
                                       </Box>
                                    ) : null}
                                    {yourIntention ? (
                                       <Text size="paragraphLarge">
                                          {yourIntention}
                                       </Text>
                                    ) : null}
                                 </>
                              ) : null}
                           </Flex>
                        </AccordionPanel>
                     </>
                  );
               }}
            </AccordionItem>
         </Accordion>
      </>
   );
};
