import { Flex, Skeleton, useDisclosure } from '@chakra-ui/react';
import {
   type ConfirmDosageDto,
   IntakeProgramPhases,
   WelkinPrograms,
} from '@innerwell/dtos';
import { isIntakeProgramAfterOrEqual } from '@innerwell/utils';
import { useQueryClient } from '@tanstack/react-query';

import { useConfirmDosage } from '@/hooks/react-query/useConfirmDosage';
import { useDosingHistory } from '@/hooks/react-query/useDosingHistory';

import { usePatientProgram } from '@/contexts/patient-program-context';
import adsLocalStorage from '@/utils/adsLocalStorage';

import { DosingInfoCard } from '../Cards/DosingInfoCard';
import { JourneyWelcomeScreen } from '../Journey/JourneyWelcomeScreen';
import ConfirmDosageModal from '../Modals/ConfirmDosageModal';

import { queryKeys } from '@/types/query-keys';

export const SessionsTab = () => {
   const {
      programPhase: { program, phase },
   } = usePatientProgram();

   const queryClient = useQueryClient();

   const { data, isLoading } = useDosingHistory();

   const dosingSessions = data ?? [];

   // Record offline experience
   const { mutate: confirmDosage } = useConfirmDosage();

   const confirmDosageModal = useDisclosure();

   const handleCompletedOutsidePlatformClick = () => {
      confirmDosageModal.onOpen();
   };

   const handleConfirmDosageSubmit = async (data: ConfirmDosageDto) => {
      confirmDosage(data);

      queryClient.refetchQueries({
         queryKey: queryKeys.dosingHistory,
      });
   };

   const isAfterMedicalConsult =
      program !== WelkinPrograms.Intake ||
      (program === WelkinPrograms.Intake &&
         isIntakeProgramAfterOrEqual(
            phase,
            IntakeProgramPhases.MedicalConsultFinished,
         ));

   return (
      <Skeleton isLoaded={!isLoading} minH="105px" borderRadius="12px" mt={8}>
         {!isLoading && dosingSessions.length === 0 ? (
            <>
               <JourneyWelcomeScreen
                  text={`Tracking your ketamine sessions can help you and your healthcare provider understand the effectiveness of treatment and make informed adjusts to your plan.
               `}
                  image="/images/welcome-dosing-sessions.png"
                  subText="Available once you have completed your first ketamine experience."
                  btnLabel={
                     isAfterMedicalConsult ? 'Start your experience' : ''
                  }
                  btnLink={
                     adsLocalStorage.isWelcomePopupSeen() ? '/ads/form' : '/ads'
                  }
                  secondaryBtnLabel={
                     isAfterMedicalConsult ? 'Record an offline experience' : ''
                  }
                  onSecondaryBtnClick={handleCompletedOutsidePlatformClick}
               />
               <ConfirmDosageModal
                  onFormSubmit={handleConfirmDosageSubmit}
                  {...confirmDosageModal}
               />
            </>
         ) : (
            <Flex flexDir="column" gap={4}>
               {dosingSessions.map((session) => {
                  return (
                     <DosingInfoCard key={session.date} session={session} />
                  );
               })}
            </Flex>
         )}
      </Skeleton>
   );
};
