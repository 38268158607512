import { DateTime } from 'luxon';

import {
   type ChartMargin,
   type ChartPeriod,
   periodMap,
} from '@/components/Charts/DailyMoodChart';

type Props = {
   xLabels: Date[];
   period: ChartPeriod;
   xScale: d3.ScaleTime<number, number, never>;
   height: number;
   margin: ChartMargin;
   strokeColor: string;
};

export const ChartXAxis: React.FC<Props> = ({
   xLabels,
   period,
   xScale,
   height,
   margin,
   strokeColor,
}) => {
   return (
      <>
         {xLabels.map((date) => {
            const label = periodMap[period].xLabelFormat(date);

            return (
               <g
                  transform={`translate(${xScale(date)}, 0)`}
                  key={DateTime.fromJSDate(date).toISO()}
               >
                  <line
                     y1={margin.top}
                     y2={height - margin.bottom + 10}
                     stroke={strokeColor}
                     strokeWidth={1}
                  />

                  <g transform={`translate(0, ${height - margin.bottom + 15})`}>
                     <text
                        textAnchor="middle"
                        fill="#121723"
                        opacity="0.6"
                        fontSize="12"
                        dy="1.2em"
                     >
                        {label}
                     </text>
                  </g>
               </g>
            );
         })}
      </>
   );
};
