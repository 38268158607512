'use client';

import { Box, type BoxProps } from '@chakra-ui/layout';
import { type ColorProps, Flex } from '@chakra-ui/react';
import FormsortWebEmbed, {
   type IEventMap,
   SupportedAnalyticsEvent,
} from '@formsort/web-embed-api';
import {
   FormsortFormTypes,
   INITIAL_SCREENING_FORM_TYPES,
   type SignupScreeningAnswers,
   TherapyPlan,
} from '@innerwell/dtos';
import { prettifyPhoneNumber } from '@innerwell/utils';
import { type Route } from 'next';
import { useRouter } from 'next/navigation';

import { InnerwellAnimatedLogo } from '@/components/Images';

import { webApiClient } from '@/api-client/apiClient';
import { useSession } from '@/contexts/session-context';
import { getClientPublicRuntimeConfig } from '@/services/env/utils/client-public-runtime-config';
import formsortLocalStorage from '@/utils/formsortLocalStorage';
import { usePatientProgram } from '@/contexts/patient-program-context';
import usePatientLastCarePlanChoice from '@/hooks/react-query/usePatientLastCarePlanChoice';
import { useEffect, useRef, useState } from 'react';

export type FormsortParam = [string, string];

interface IProps extends BoxProps {
   flowName: FormsortFormTypes;
   desktopImage?: React.ReactNode;
   onFormsortLoaded?: () => void;
   onStepChanged?: (step: string) => void;
   onStepCompleted?: IEventMap['StepCompleted'];
   additionalParams?: FormsortParam[];
   loadingTextColor?: ColorProps['color'];
   isLoading?: boolean;
   onFinalized?: () => void;
}

const getProgramName = (chosenCarePlan: TherapyPlan) => {
   if (
      [TherapyPlan.GeneralTherapyPlan, TherapyPlan.EMDR].includes(
         chosenCarePlan,
      )
   ) {
      return 'therapy';
   }

   if (chosenCarePlan === TherapyPlan.GeneralPsychiatryPlan) {
      return 'psychiatry';
   }

   return chosenCarePlan;
};

const FormsortWrapper: React.FC<IProps> = ({
   flowName,
   desktopImage,
   onFormsortLoaded,
   onStepChanged,
   onFinalized,
   additionalParams,
   loadingTextColor = 'white',
   isLoading: isLoadingProp = false,
   onStepCompleted,
   ...boxProps
}) => {
   const {
      programPhase: { program },
   } = usePatientProgram();
   const divRef = useRef<HTMLDivElement>(null);
   const [isFormLoading, setIsFormLoading] = useState(true);
   const isLoading = isLoadingProp || isFormLoading;
   const { chosenCarePlan, isLoading: isLoadingLastCarePlan } =
      usePatientLastCarePlanChoice();

   const session = useSession();
   const { push } = useRouter();

   useEffect(() => {
      if (onFormsortLoaded && isFormLoading === false) {
         onFormsortLoaded();
      }
   }, [isFormLoading, onFormsortLoaded]);

   useEffect(() => {
      const currentRef = divRef.current;

      if (
         !currentRef ||
         session.status === 'loading' ||
         isLoadingProp ||
         // We need to wait for the last care plan choice to load for the psychiatry and therapy intake form
         (flowName === FormsortFormTypes.PsychiatryAndTherapyIntake &&
            isLoadingLastCarePlan)
      ) {
         return;
      }

      if (!isFormLoading) {
         console.error(
            `FormsortWrapper: Formsort was loaded and now we're trying to load it again. This is a bug and causes form reset.`,
         );
      }

      const handleFormsortLoaded: IEventMap['FlowLoaded'] = () => {
         setIsFormLoading(false);
      };

      const embed = FormsortWebEmbed(currentRef, {
         style: { width: '100%', height: '100%' },
      });

      const handleFormsortStepLoaded: IEventMap['StepLoaded'] = (props) => {
         const currentStepId = props.answers?.current_step_id as
            | string
            | undefined;

         if (onStepChanged && currentStepId) {
            onStepChanged(currentStepId);
         }
      };

      embed.addEventListener(
         SupportedAnalyticsEvent.StepLoaded,
         handleFormsortStepLoaded,
      );

      embed.addEventListener(
         SupportedAnalyticsEvent.FlowLoaded,
         handleFormsortLoaded,
      );

      const handleFormClosed = () => {
         push('/');
      };

      embed.addEventListener(
         SupportedAnalyticsEvent.FlowClosed,
         handleFormClosed,
      );

      const handleFormRedirect = (props: { url: string }) => {
         const { url } = props;

         if (onFinalized) {
            onFinalized();
         }

         const parsedUrl = new URL(url);
         push(
            `${parsedUrl.pathname}${parsedUrl.search}${parsedUrl.hash}` as Route,
         );

         return {
            cancel: true,
         };
      };

      const handleFormFinalized: IEventMap['FlowFinalized'] = (
         formsortData,
      ) => {
         if (formsortData.answers) {
            formsortLocalStorage.setInitialScreeningAnswers(
               formsortData.answers,
            );
         }
      };

      embed.addEventListener('redirect', handleFormRedirect);
      embed.addEventListener(
         SupportedAnalyticsEvent.FlowFinalized,
         handleFormFinalized,
      );

      if (onStepCompleted) {
         embed.addEventListener(
            SupportedAnalyticsEvent.StepCompleted,
            onStepCompleted,
         );
      }

      const loadFormsortFlow = async () => {
         const [res, patientBmiRes] =
            session.status === 'authenticated'
               ? await Promise.all([
                    webApiClient.formsort.list({
                       query: {
                          formsort_form_type: INITIAL_SCREENING_FORM_TYPES,
                       },
                    }),
                    webApiClient.patients.getBmi(),
                 ])
               : [null, null];

         const answers = res?.body[0]?.answers as
            | SignupScreeningAnswers
            | undefined;

         const flowParams = [
            ['formsortEnv', getClientPublicRuntimeConfig().formsortEnv],
         ] as FormsortParam[];

         if (session.data) {
            flowParams.push(
               [
                  'responderUuid',
                  session.data['custom:formsortUUID'] ??
                     session.data['custom:welkinUserId'] ??
                     '',
               ],
               ['cognitoSub', session.data['cognito:username']],
               ['user_first-name', session.data.given_name],
               ['user_last-name', session.data.given_name],
               ['user_email', session.data.email],
               ['user_phone', prettifyPhoneNumber(session.data.phone_number)],
            );
         }

         if (answers) {
            if (answers.initial_phq9_score) {
               flowParams.push([
                  'initialPHQ9Score',
                  answers.initial_phq9_score.toString(),
               ]);
            }

            if (answers.initial_gad7_score) {
               flowParams.push([
                  'initialGAD7Score',
                  answers.initial_gad7_score?.toString(),
               ]);
            }

            if (answers.hasDepression) {
               flowParams.push([
                  'show-depression',
                  answers.hasDepression.toString(),
               ]);
            }

            if (answers.hasAnxiety) {
               flowParams.push(['show-anxiety', answers.hasAnxiety.toString()]);
            }

            if (answers.what_brings_you_to_innerwell) {
               flowParams.push([
                  'what_brings_you_to_innerwell',
                  answers.what_brings_you_to_innerwell.toString(),
               ]);
            }
         }

         if (
            patientBmiRes?.body &&
            flowName === FormsortFormTypes.MedicalIntakePsychiatricHistory
         ) {
            flowParams.push(['bmi', patientBmiRes.body.bmi.toString()]);
         }

         if (additionalParams && additionalParams.length) {
            flowParams.push(...additionalParams);
         }

         if (
            flowName === FormsortFormTypes.MedicalIntakeProfile ||
            flowName === FormsortFormTypes.PsychiatryAndTherapyIntake
         ) {
            const myPatient = await webApiClient.account.getPatient();

            if (myPatient.body?.state) {
               flowParams.push(['user_state', myPatient.body.state]);

               if (myPatient.body.birthDate) {
                  flowParams.push([
                     'user_existing_dob',
                     myPatient.body.birthDate.toString(),
                  ]);
               }
            }
         }

         if (chosenCarePlan) {
            const programName = getProgramName(chosenCarePlan);
            flowParams.push(['program_name', programName]);
         }

         if (flowName === FormsortFormTypes.ADS) {
            // Populate sitter info
            const sitterRes = await webApiClient.patients.getSitter();
            const sitter = sitterRes.body;
            if (sitter) {
               flowParams.push(
                  ['current_sitter_name', sitter.name],
                  ['current_sitter_email', sitter.email],
                  ['current_sitter_relationship', sitter.relationship],
                  [
                     'current_sitter_phone',
                     prettifyPhoneNumber(sitter.phoneNumber),
                  ],
               );
            }
         }

         embed.loadFlow('Innerwell', flowName, 'main', flowParams);
      };

      loadFormsortFlow();

      return () => {
         if (onStepCompleted) {
            embed.removeEventListener(
               SupportedAnalyticsEvent.StepCompleted,
               onStepCompleted,
            );
         }

         embed.removeEventListener(
            SupportedAnalyticsEvent.FlowLoaded,
            handleFormsortLoaded,
         );
         embed.removeEventListener(
            SupportedAnalyticsEvent.FlowClosed,
            handleFormClosed,
         );
         embed.removeEventListener(
            SupportedAnalyticsEvent.FlowFinalized,
            handleFormFinalized,
         );

         embed.removeEventListener('redirect', handleFormRedirect);

         currentRef.innerHTML = '';
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [
      additionalParams,
      flowName,
      isLoadingProp,
      onFinalized,
      onStepChanged,
      onStepCompleted,
      session,
      program,
      chosenCarePlan,
   ]);

   return (
      <>
         {isLoading ? (
            <Flex
               minH="var(--app-height)"
               h="full"
               alignItems="center"
               justifyContent="center"
            >
               <InnerwellAnimatedLogo fill={loadingTextColor} />{' '}
            </Flex>
         ) : (
            <Box
               display={{ base: 'none', md: 'block' }}
               position="absolute"
               right="0"
               bottom="0"
               maxW="300px"
               overflow="hidden"
            >
               <Box transform="translateY(25%)">{desktopImage}</Box>
            </Box>
         )}

         <Box
            minH="var(--app-height)"
            alignItems="stretch"
            display={isFormLoading ? 'none' : 'flex'}
            width="100%"
            ref={divRef}
            pos="relative"
            h="auto"
            zIndex={2}
            sx={{
               iframe: {
                  height: 'auto !important',
               },
            }}
            transition="all .3s ease"
            opacity={isFormLoading ? '0' : '1'}
            {...boxProps}
         />
      </>
   );
};

export default FormsortWrapper;
