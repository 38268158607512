import {
   Box,
   Button,
   Divider,
   Flex,
   Skeleton,
   Tab,
   TabList,
   TabPanel,
   TabPanels,
   Tabs,
   Text,
   Tooltip,
   useDisclosure,
} from '@chakra-ui/react';
import { MoodTrackingRangeEnum } from '@innerwell/dtos';
import _ from 'lodash';
import { DateTime } from 'luxon';
import { parseAsInteger, useQueryState } from 'nuqs';
import { useMemo, useState } from 'react';

import { useDailyMoodTracking } from '@/hooks/react-query/useDailyMoodTracking';
import { useInfiniteDailyMoods } from '@/hooks/react-query/useInfiniteDailyMoods';
import { useWeeklyTrackProgress } from '@/hooks/react-query/useWeeklyTrackProgress';

import JourneyMoodCard from '@/components/Cards/JourneyMoodCard';
import { NextLinkButton } from '@/components/NextLinkButton/NextLinkButton';

import { ChartProvider } from '@/contexts/chart-context';

import {
   SandCirclesChartBottomRight,
   SandCirclesChartTopLeft,
} from '../BackgroundGraphics';
import {
   DailyMoodChart,
   DateInfoAndTrackCTA,
   MedicineSessionsToggle,
} from '../Charts/DailyMoodChart';
import { LevelsChart } from '../Charts/LevelsChart';
import { JourneyWelcomeScreen } from '../Journey/JourneyWelcomeScreen';
import DailyMoodTrackingModal from '../Modals/DailyMoodTrackingModal';
import { SkeletonWithLoader } from '../Loaders/SkeletonWithLoader';

enum NavTabs {
   Week,
   Month,
   SixMonths,
   Year,
}

export const MoodsTab = () => {
   const [tabIndex, setTabIndex] = useQueryState(
      'dailyMoodPeriod',
      parseAsInteger.withDefault(NavTabs.Week),
   );

   const { data, status, fetchNextPage, hasNextPage, isFetchingNextPage } =
      useInfiniteDailyMoods();

   const handleTabChange = (newIndex: NavTabs) => {
      setTabIndex(newIndex);
   };

   // Mood tracking
   const [isTodayMoodTracked, setIsTodayMoodTracked] = useState(false);

   const { data: dailyMoodData, status: dailyMoodStatus } =
      useDailyMoodTracking();

   const isWeeklyPauseTracked = !dailyMoodData?.sendWeeklyPause;

   const isTodayFilledIn = useMemo(() => {
      const currentDatetime = DateTime.now();
      const today = currentDatetime.toISODate();
      const day = dailyMoodData?.segments.find((d) => d.date === today);
      return day?.score ? true : false;
   }, [dailyMoodData]);

   const initialMoodTrackingModal = useDisclosure();
   const handleFinishedDailyMoodTracking = () => {
      setIsTodayMoodTracked(true);
   };

   const handleStartMoodTracking = () => {
      initialMoodTrackingModal.onOpen();
   };

   const allMoods =
      _.flatten(data?.pages.map((page) => page.moodData)).filter((mood) =>
         Boolean(mood.date),
      ) ?? [];

   const { anxiety, depression, ptsd } = useWeeklyTrackProgress();

   return dailyMoodStatus === 'pending' ? (
      <Skeleton w="full" mt={8} h="300px" borderRadius="12px" />
   ) : (
      <>
         <DailyMoodTrackingModal
            isOpen={initialMoodTrackingModal.isOpen}
            onClose={initialMoodTrackingModal.onClose}
            onFinishedDailyMoodTracking={handleFinishedDailyMoodTracking}
            date={DateTime.now().toISODate()}
         />

         {allMoods.length === 0 ? (
            <JourneyWelcomeScreen
               text={`One of the first steps towards learning about what’s
                     going on inside of your mind is to begin cultivating awareness.
                     Tracking your mood can help you connect with how you’re
                     feeling each day, while also communicating your emotional
                     progress with your care team.`}
               image="/images/welcome-screen-mood-tracking.jpg"
               btnLabel="Start tracking your daily mood"
               onBtnClick={handleStartMoodTracking}
            />
         ) : (
            <Box mt={{ base: 5, lg: 6 }}>
               <Flex
                  alignItems="center"
                  flexDir={{ base: 'column', lg: 'row' }}
                  mb={8}
               >
                  <Text fontWeight={500}>
                     One of the first steps towards learning about what’s going
                     on inside of our brains is to begin by cultivating
                     awareness. Daily tracking of your mood can help you
                     identify patterns, manage your emotions better, and improve
                     your overall well-being.
                  </Text>
                  <Tooltip
                     textColor="white"
                     label={`You've already tracked your mood today. Keep up the
                           good work! Come back tomorrow to continue your mindfulness
                           journey.`}
                     shouldWrapChildren
                     hasArrow
                     isDisabled={!isTodayMoodTracked && !isTodayFilledIn}
                  >
                     <Button
                        onClick={handleStartMoodTracking}
                        size="sm"
                        flexShrink={0}
                        display="block"
                        zIndex={3}
                        ml={{ lg: 14 }}
                        mt={{ base: 4, lg: 0 }}
                        minW="200px"
                        w={{ base: 'auto', lg: 'auto' }}
                        isDisabled={isTodayFilledIn || isTodayMoodTracked}
                     >
                        Track now
                     </Button>
                  </Tooltip>
               </Flex>
               <Flex
                  flexDir="column"
                  bg="white"
                  borderRadius="12px"
                  mt={{ base: 5, lg: 8 }}
                  p={{ base: 5, lg: 6 }}
                  pt={5}
                  maxW="100%"
                  pos="relative"
                  overflow="hidden"
               >
                  <SandCirclesChartTopLeft pos="absolute" left={0} top={0} />
                  <SandCirclesChartBottomRight
                     pos="absolute"
                     right={0}
                     bottom={0}
                  />

                  <Text
                     pos={{ base: 'relative', lg: 'absolute' }}
                     size="leadText"
                     fontWeight={500}
                     color="text.primary"
                     mb={4}
                     left={{ lg: 6 }}
                     top={{ lg: 7 }}
                  >
                     Daily Moods
                  </Text>
                  <Tabs
                     variant="pill"
                     index={tabIndex}
                     onChange={handleTabChange}
                     isLazy
                  >
                     <TabList
                        alignItems="stretch"
                        bg="background.secondary"
                        maxW={{ lg: '270px' }}
                        mx="auto"
                        mt={{ lg: 0.5 }}
                        mb={3}
                     >
                        <Tab>W</Tab>
                        <Tab>M</Tab>
                        <Tab>6M</Tab>
                     </TabList>

                     <TabPanels>
                        <TabPanel py={0}>
                           <ChartProvider>
                              <DateInfoAndTrackCTA period="w" />

                              <DailyMoodChart
                                 height="330px"
                                 period="w"
                                 periodSegment={MoodTrackingRangeEnum.Day}
                              />
                              <MedicineSessionsToggle />
                           </ChartProvider>
                        </TabPanel>
                        <TabPanel py={0}>
                           <ChartProvider>
                              <DateInfoAndTrackCTA period="m" />
                              <DailyMoodChart
                                 height="330px"
                                 period="m"
                                 periodSegment={MoodTrackingRangeEnum.Day}
                              />
                              <MedicineSessionsToggle />
                           </ChartProvider>
                        </TabPanel>
                        <TabPanel py={0}>
                           <ChartProvider>
                              <DateInfoAndTrackCTA period="6m" />
                              <DailyMoodChart
                                 height="330px"
                                 period="6m"
                                 periodSegment={MoodTrackingRangeEnum.Week}
                              />
                              <MedicineSessionsToggle />
                           </ChartProvider>
                        </TabPanel>
                     </TabPanels>
                  </Tabs>
               </Flex>
               {status === 'success' ? (
                  <Flex flexDir="column" gap={4} mt={5} pb={6}>
                     {allMoods.map((mood) => {
                        return <JourneyMoodCard key={mood.id} mood={mood} />;
                     })}

                     {hasNextPage && !isFetchingNextPage ? (
                        <Button
                           variant="link"
                           color="white"
                           mx="auto"
                           onClick={() => {
                              fetchNextPage();
                           }}
                           mt={6}
                        >
                           View more
                        </Button>
                     ) : null}
                  </Flex>
               ) : null}

               {isFetchingNextPage ? (
                  <SkeletonWithLoader
                     minH="113px"
                     loadingText="Loading more mood data..."
                     borderRadius={12}
                     mt={4}
                  />
               ) : null}

               {anxiety.data?.length ||
               depression.data?.length ||
               ptsd.data?.length ||
               !isWeeklyPauseTracked ? (
                  <>
                     <Divider mt={10} mb={6} />
                     <Flex
                        alignItems="center"
                        flexDir={{ base: 'column', lg: 'row' }}
                        mb={8}
                     >
                        <Text fontWeight={500}>
                           Tracking your depression (PHQ), Anxiety (GAD) and
                           Stress (PCL-5) levels can provide you and your
                           assigned clinician ways to identify progress, make
                           treatment decisions, and improve overall mental
                           health outcomes.
                        </Text>
                        <Tooltip
                           textColor="white"
                           label={`You've already tracked your weekly progress.
                                 Keep up the good work! Come back in a few days to
                                 continue your mindfulness journey.`}
                           shouldWrapChildren
                           hasArrow
                           isDisabled={!isWeeklyPauseTracked}
                        >
                           <NextLinkButton
                              href="/weekly-pause"
                              size="sm"
                              flexShrink={0}
                              isDisabled={isWeeklyPauseTracked}
                              zIndex={3}
                              ml={{ lg: 14 }}
                              mt={{ base: 4, lg: 0 }}
                              minW="200px"
                              w={{ base: 'auto', lg: 'auto' }}
                           >
                              Track now
                           </NextLinkButton>
                        </Tooltip>
                     </Flex>
                     {depression.data && depression.data.length > 0 ? (
                        <Flex
                           flexDir="column"
                           bg="white"
                           borderRadius="12px"
                           my={12}
                           p={6}
                           pt={7}
                           pos="relative"
                           overflow="hidden"
                        >
                           <SandCirclesChartTopLeft
                              pos="absolute"
                              left={0}
                              top={0}
                           />
                           <SandCirclesChartBottomRight
                              pos="absolute"
                              right={0}
                              bottom={0}
                           />

                           <Text
                              size="leadText"
                              fontWeight={500}
                              color="text.primary"
                           >
                              Depression Level
                           </Text>
                           <LevelsChart
                              levels={depression.data}
                              type="depression"
                              height="300px"
                           />
                        </Flex>
                     ) : null}

                     {anxiety.data && anxiety.data.length > 0 ? (
                        <Flex
                           flexDir="column"
                           bg="white"
                           borderRadius="12px"
                           my={12}
                           p={6}
                           pt={7}
                           pos="relative"
                           overflow="hidden"
                        >
                           <SandCirclesChartTopLeft
                              pos="absolute"
                              left={0}
                              top={0}
                           />
                           <SandCirclesChartBottomRight
                              pos="absolute"
                              right={0}
                              bottom={0}
                           />

                           <Text
                              size="leadText"
                              fontWeight={500}
                              color="text.primary"
                           >
                              Anxiety Level
                           </Text>
                           <LevelsChart
                              levels={anxiety.data}
                              type="anxiety"
                              height="300px"
                           />
                        </Flex>
                     ) : null}

                     {ptsd.data && ptsd.data.length > 0 ? (
                        <Flex
                           flexDir="column"
                           bg="white"
                           borderRadius="12px"
                           my={12}
                           p={6}
                           pt={7}
                           pos="relative"
                           overflow="hidden"
                           mb={20}
                        >
                           <SandCirclesChartTopLeft
                              pos="absolute"
                              left={0}
                              top={0}
                           />
                           <SandCirclesChartBottomRight
                              pos="absolute"
                              right={0}
                              bottom={0}
                           />

                           <Text
                              size="leadText"
                              fontWeight={500}
                              color="text.primary"
                           >
                              Stress Level
                           </Text>
                           <LevelsChart
                              levels={ptsd.data}
                              type="ptsd"
                              height="300px"
                           />
                        </Flex>
                     ) : null}
                  </>
               ) : null}
            </Box>
         )}
      </>
   );
};
